const scanErrorHandle = {
    namespaced: true,
    state: {
        scoreErrorTabVisible: false,
        optionErrorTabVisible: false,
        paperCoordInfo: {},
        examInfo: {},
        deleteFlag: 0,//异常处理删除学生时 查看全部面板已经显示 这时候要更新查看面板里的数据 popover组件watch这个值的变化进行更新
        coordInfoObj: {}, // 当前考试 框选的的坐标信息 
        exampleCoord: {}, // 查看框选的模板
        hideHandle: false, // 从成绩报表 点击选择题进来的时候 隐藏四种处理方式 只显示 修改选择题答案
        preUploadSheetCountInfo: [], // 储存用户预先上传的答题卡面数
        lowerRateSubjectArr: [],// 选择题得分率比较低的科目数组
        hasScanErrorCountArr: [], // 有扫描异常的科目数组
        scanTotalCount: 0,
        scanErrorCount: 0,
        choiceCoordErrorCount: 0,
        handleCoordErrorMode: 0 // 处理定位异常时 框选的方式 0是拖动方框 1是鼠标框选
    },
    mutations: {
        UPDATE_CORRD_INFO(state, { key, data }) {
            state.coordInfoObj[key] = data
        },
        UPDATE_SCORE_ERROR_TAB_VISIBLE(state, boolean) {
            state.scoreErrorTabVisible = boolean;
        },
        UPDATE_OPTION_ERROR_TAB_VISIBLE(state, param) {
            state.optionErrorTabVisible = param;
        },
        // 已经框选后 查看全部切换试卷时 保存已经框选的坐标信息
        SAVE_COORD_INFO(state, data) {
            state.paperCoordInfo[data.id] = data.coordArr;
        },
        DELETE_COORD_INFO(state, id) {
            if (state.paperCoordInfo[id]) {
                delete state.paperCoordInfo[id];
            }
        },
        CHANGE_EXAM_INFO(state, info) {
            state.examInfo = info;
        },
        UPDATE_DELETE_COUNT(state) {
            state.deleteFlag++
        },
        UPDATE_EXAMPLE_COORD(state, data) {
            state.exampleCoord = data
        },
        UPDATE_HIDE_HANDLE(state, boolean) {
            state.hideHandle = boolean
        },
        SAVE_PRE_UPLOAD_SHEET_COUNT(state, data) {
            const { examId, subjectId, sheetCount } = data
            const index = state.preUploadSheetCountInfo.findIndex(r => r.examId === examId && r.subjectId === subjectId)
            if (index === -1) {
                state.preUploadSheetCountInfo.push(data)
            } else {
                state.preUploadSheetCountInfo.splice(index, 1, {
                    ...state.preUploadSheetCountInfo[index],
                    sheetCount
                })
            }
        },
        CLEAR_PRE_UPLOAD_SHEET_COUNT(state, data) {
            const { examId, subjectId } = data
            const index = state.preUploadSheetCountInfo.findIndex(r => r.examId === examId && r.subjectId === subjectId)
            if (index !== -1) {
                state.preUploadSheetCountInfo.splice(index, 1)
            }
        },
        UPDATE_TOTAL_TAB_SUBJECT_ERROR_INFO(state, payload) {
            const { lowerRateSubjectArr, hasScanErrorCountSubjectArr } = payload
            state.lowerRateSubjectArr = Array.isArray(lowerRateSubjectArr) ? lowerRateSubjectArr : []
            state.hasScanErrorCountArr = Array.isArray(hasScanErrorCountSubjectArr) ? hasScanErrorCountSubjectArr : []
        },
        UPDATE_SINGLE_SUBJECT_SCAN_ERROR_COUNT(state, payload) {
            const { scanErrorCount, choiceCoordErrorCount, scanTotalCount } = payload
            state.scanTotalCount = scanTotalCount
            state.scanErrorCount = scanErrorCount
            state.choiceCoordErrorCount = choiceCoordErrorCount
        },
        CLEAR_WEB_WORKER_DATA(state) {
            state.lowerRateSubjectArr = []
            state.hasScanErrorCountArr = []
            state.scanTotalCount = 0
            state.scanErrorCount = 0
            state.choiceCoordErrorCount = 0
        },
        UPDATE_HANDLE_COORD_ERROR_MODE(state, mode) {
            state.handleCoordErrorMode = mode
        }
    },
}

export default scanErrorHandle