import API from '@/modules/exam-report/api';
import { analyzeGradeMap, analyzeSubjectMap } from '@/scripts/analyzeMap';
import { isEmptyArray, isArray } from '@/scripts/validate';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import serve from '@/scripts/serve'

let timeId = null;

/**
 * vuex 的成绩报表模块
 * 为了简化操作，直接在 mutations 里修改 state 的值了
 *
 */
export default {
  namespaced: true,
  state: () => {
    return {
      exam: {}, // 考试信息

      districts: [], // tree结构 行政区域 目前只有地级和乡镇
      cityOptions: [],
      districtOptions: [],
      schoolAndClasses: [], // tree结构 学校和班级
      schoolOptions: [],
      classesOptions: [],
      levelProgress: 0,
      teacherVisiable: true, //排名对老师是否可见
      studentVisiable: false, //排名对学生是否可见
      highSubjectList: [],    // 高中报表目列表
    };
  },
  getters: {
    examId: (state) => (state.exam.id || ''),
    examName: (state) => (state.exam.name || ''),
    gradeId: (state) => (state.exam.grade_id || ''),
    isHighStage: (state, getters) => {
      return getters.gradeId >= 10;
    },
    gradeStage: (state, getters) => {
      const gradeId = getters.gradeId;
      if (!gradeId) return '';
      return analyzeGradeMap[gradeId].stage;
    },
    gradeName: (state, getters) => {
      const gradeId = getters.gradeId;
      if (!gradeId) return '';
      return analyzeGradeMap[gradeId].name;
    },
    subjectIds: (state) =>
      state.exam
        ? state.exam.subject_ids || (state.exam.subject_id && [state.exam.subject_id]) || []
        : [],

    subjectOptions: (state, getters, rootState, rootGetters) => {
      // 当前考试的科目
      let subjectIds = getters.subjectIds;
      const permissionSubjectList = rootGetters['user/permissionSubjectList'];
      console.log(permissionSubjectList);
      let res = [];
      if (isArray(subjectIds)) {
        if (permissionSubjectList.length) {
          subjectIds = subjectIds.filter(e => permissionSubjectList.find(u => u.id == e));
        }
        res = subjectIds.map((id) => {
          let subjectLabel = analyzeSubjectMap[id]
          if (state.exam && state.exam.grade_id && state.exam.grade_id <= 9 && id === 7) {
            subjectLabel = '道德与法治'
          }
          if (state.exam && state.exam.grade_id && state.exam.grade_id >= 10 && id === 7) {
            subjectLabel = '思想政治'
          }
          return {
            value: id,
            label: subjectLabel,
          };
        });
      }
      return res;
    },

    // 高中报表**单科**科目列表
    // highSubjectList: (state, getters, rootState, rootGetters) => {
    //   const permissionSubjectList = rootGetters['user/permissionSubjectList'];
    //   let list = [];
    //   for (const [, v] of Object.entries(highExamSubject)) {
    //     list = list.concat(v);
    //   }
    //   if (permissionSubjectList.length) {
    //     list = list.filter(e => permissionSubjectList.find(u => u.id === e.id));
    //   }
    //   return list;
    // },
  },
  mutations: {
    CLEAR_SCHOOL_AND_CLASSES(state) {
      state.schoolAndClasses = [];
    },
    GET_CITY_OPTIONS(state) {
      const { districts } = state;
      let list = [];
      if (isArray(districts)) {
        list = districts.map(({ F_id, F_level, F_name, F_pid }) => {
          return { F_id, F_level, F_name, F_pid };
        });
      }
      state.cityOptions = list;
    },

    // 根据城市id获取镇区选项列表
    GET_DISTRICT_OPTIONS(state, payload) {
      const match = state.districts.find((_) => _.F_id === payload.cityId);
      state.districtOptions = match.children;
    },

    getSchoolOptions(state, payload) {
      let { districtId } = payload;
      let list = [];
      if (!districtId || isEmptyArray(districtId)) {
        list = JSON.parse(JSON.stringify(state.schoolAndClasses));
      } else {
        districtId = isArray(districtId) ? districtId : [districtId];
        list = state.schoolAndClasses.filter(e => districtId.indexOf(e.district_id) > -1);
      }
      state.schoolOptions = list;
    },

    cancelGetProgress() {
      clearInterval(timeId);
      timeId = null;
    },
    // changeRankingVisiable(state, payload) {
    //   state.teacherVisiable = Boolean(payload.is_show_rank_to_teacher);
    //   state.studentVisiable = Boolean(payload.is_show_rank_to_student);
    // }

  },
  actions: {
    changeRankingVisiable({ state, rootState }, payload) {
      const { user: { permission: {  role, school_id } }} = rootState;
      if(school_id == 1000697) {
        const roleList = ['校长', '校务管理员'];
        const value = payload.is_show_rank_to_teacher && roleList.includes(role);
        state.teacherVisiable = Boolean(value);
      } else {
        state.teacherVisiable = Boolean(payload.is_show_rank_to_teacher);
      }
      state.studentVisiable = Boolean(payload.is_show_rank_to_student);
    },
    /** 获取这场考试的全部信息 */
    async GET_EXAM_INFO({ state }, payload) {
      const res = await API.exam.getExamInfo(payload);
      state.exam = res;
    },

    // 获取考试区域信息
    async GET_DISTRICTS({ state, commit }, payload) {
      const { examId, countyList } = payload;
      let list = await API.examReport.getDistricts(examId);
      if (countyList) {
        const group = groupBy(countyList, 'F_pid');
        Object.entries(group).forEach(([cityId, countyList]) => {
          cityId = Number(cityId);
          const countyIds = countyList.map((_) => _.F_id);
          const index = list.findIndex((_) => _.F_id === cityId);
          if (index > -1) {
            list[index].children = list[index].children.filter(
              (_) => countyIds.indexOf(_.F_id) > -1
            );
          }
        });
      }
      state.districts = list;
      commit('GET_CITY_OPTIONS');
    },

    async getSchoolAndClasses({ state }, payload) {
      let res = await API.examReport.getSchoolAndClasses(payload);
      // 先将学校按学校id排序
      res.sort((a, b) => {
        return a.school_id - b.school_id;
      });
      // 再按districtIds的镇区顺序排序
      const districtIds = state.districtOptions.map(e => e.F_id);
      res.sort((a, b) => {
        return districtIds.indexOf(a.district_id) - districtIds.indexOf(b.district_id);
      });
      // 班级按照名字排序
      // res.forEach((_) => {
      //   _.classes.forEach((item) => {
      //     let classNum = item.class_name.replace(/[^0-9]/gi, '');
      //     item.classNum = Number(classNum);
      //   });
      //   _.classes = orderBy(_.classes, 'classNum', ['asc']);
      // });
      state.schoolAndClasses = res;
    },

    getClassesOptions({ state, rootState }, payload) {
      let { schoolId, needGroupBy } = payload;
      let list = [];
      if (!schoolId || isEmptyArray(schoolId)) {
        list = JSON.parse(JSON.stringify(state.schoolAndClasses));
      } else {
        schoolId = isArray(schoolId) ? schoolId : [schoolId];
        list = state.schoolAndClasses.filter((_) => schoolId.indexOf(_.school_id) > -1);

        // 只考虑单校的时候，读取该账号的 class_list，有的话取交集
        const { user: { permission: { permission: { class_list } } } } = rootState;
        if (schoolId.length === 1 && class_list.length) {
          const ids = class_list.map(e => e.F_id);
          list[0].classes = list[0].classes.filter(e => ids.includes(e.class_id))
        }
      }
      // classesOptions为多个校的班级列表,以校分,如果是学校教师或者学校筛选是单选,则取第一项(也只有一项)的班级列表
      list = needGroupBy ? list : list[0].classes;
      state.classesOptions = list;
    },

    async getLevelProgress({ state, commit }) {
      const { status_code, data } = await API.examReport.getJobProgress({
        mark: `update_rank_score:${state.examId}`,
      });
      // 没有正在进行的计算任务
      if (status_code !== 200) {
        state.levelProgress = 100;
        if (timeId !== null) {
          commit('cancelGetProgress')
        }
      } else {
        // 有正在进行的计算任务
        state.levelProgress = data.progress;
        if (timeId === null) {
          commit('intervalGetProgress')
        }
      }
    },

    intervalGetProgress({ dispatch }) {
      timeId = setInterval(() => {
        dispatch('getLevelProgress')
      }, 5000);
    },

    // 获取高中报表**单科**科目列表
    async getHighSubjectList({ state, rootGetters }, payload) {
      const permissionSubjectList = rootGetters['user/permissionSubjectList'];

      const { body: { data } } = await serve.getExamSubject({ exam_id: payload.examId });
      // let list = [];
      let list = [];
      (data||[]).map((id) => {
        const item = analyzeSubjectMap[id];
        if(item) {
          list.push({ id, name: item })
        }
      });
      if (permissionSubjectList.length) {
        list = list.filter(e => permissionSubjectList.find(u => u.id === e.id));
      }
      state.highSubjectList = list
    }
  },
}
