import contentType from '../instance/contentType';
import { Http } from '../instance/instance';
import domain from '@/scripts/domain';
/**
 * 考试相关
 */

const request = new Http(domain['examination-single']['exam-api-domain'], contentType.JSON, {
  onResponse(response) {
    if (response.status === 200) {
      return response.data;
    } else {
      return Promise.reject(response);
    }
  },
});

export default {
  //获取未核实的缺考列表
  getExamList: ({ school_id, teacher_id, exam_types = '0,1,2,3,4', is_joint = 1 }) =>
    request.get(`v1/exams/${school_id}`, { school_id, teacher_id, exam_types, is_joint }),

  getExamList2: function (params) {
    return request.get('v1/exams/newlist2', params)
  },

  getExamInfo: function(params) {
    const { schoolId, examId } = params;
    return request.get(`v1/exams/${schoolId}/${examId}`)
  }
};
