export default {
  path: "error-home",
  name: "error-home",
  component: () => import("./error-home.vue"),
  children: [
    {
      path: "",
      redirect: "error-summary"
    },
    {
      path: "error-summary",
      name: "error-summary",
      component: () => import("./views/error-summary.vue")
    },
    {
      path: "error-handle",
      name: "error-handle",
      component: () => import("./views/error-handle.vue")
    }
  ]
};
