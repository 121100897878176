export const isDef = (val) => val !== undefined && val !== null;

export const isUndefined = (val) => Object.prototype.toString.call(val) === '[object Undefined]';

export const isNull = (val) => Object.prototype.toString.call(val) === '[object Null]';

export const isString = (val) =>
  isDef(val) && Object.prototype.toString.call(val) === '[object String]';

export const isObject = (val) => val !== null && typeof val === 'object';

export const isEmptyObject = (val) =>
  val !== null && typeof val === 'object' && Object.keys(val).length === 0;

export const isArray = (val) =>
  isDef(val) && Object.prototype.toString.call(val) === '[object Array]';

export const isEmptyArray = (val) => isArray(val) && val.length === 0;

export const isFunction = (val) => typeof val === 'function';

export const isPromise = (val) => isObject(val) && isFunction(val.then) && isFunction(val.catch);

export const isDate = (val) =>
  Object.prototype.toString.call(val) === '[object Date]' && !Number.isNaN(val.getTime());

export const isNumeric = (val) => typeof val === 'number' || /^\d+(\.\d+)?$/.test(val);
