export default {
  JLatexServer: { domain: 'https://latex.ebag.readboy.com/' },
  'ebag-examination-web': {
    domain: 'https://single-yue.readboy.com/',
    'bigdata-report-web':
      'https://web.ebag.readboy.com/examination-web-bigdata-report/#/',
    'dream-ebag-login-domain': 'https://api.ebag.readboy.com/examination-login-single',
    'examination-answer-sheet-domain':
      'https://web.ebag.readboy.com/examination-answer-sheet-web-single/',
    'new-examination-answer-sheet-domain': 'https://ebag.readboy.com/new-single-answer-sheet',
    'word-guide-paper-web-domain': 'https://web.ebag.readboy.com/word-guide-paper-web/',
    'learning-analysis': 'https://ebag.readboy.com/learning-analysis/#/',
    'unified-examination-display-board-web': 'https://ebag.readboy.com/unified-examination-display-board',
    'scan-preogress-web': 'https://web.ebag.readboy.com/examination-scan-progress/#/',
  },
  'accurate-admin-web': {
    webDomain: 'https://yue.strongwind.cn/open-examination-admin/#/',
    'single-api-domain': 'https://api.ebag.readboy.com/examination-admin-single',
  },
  'area-teaching-quality-web': {
    domain: 'https://ebag.readboy.com/area-teaching-quality/',
  },
  'ebag-html2pdf-api': { domain: 'https://api.ebag.readboy.com/ebag-html2pdf-api' },
  'examination-answer-sheet-api': {
    QiniuDomain: 'https://ebag-lab.ebag.readboy.com/',
    domain: 'https://api.ebag.readboy.com/examination-answer-sheet-single',
  },
  zsdata: { domainResource: 'https://contres.readboy.com' },
  'examination-single': {
    'exam-api-domain': 'https://api.ebag.readboy.com/examination-single-school',
    'login-domain': 'https://api.ebag.readboy.com/examination-login-single',
    'accurate-admin-web': 'https://yue.strongwind.cn/open-examination-admin/#/',
    PDF_REPORT_WEB: 'https://ebag.readboy.com/examination-report',
  },
  'three-years-question': { THREE_YEARS_QUESTION: 'https://www.snztw.com' },
  'area-teaching-quality-web': {
    domain: 'https://ebag.readboy.com/area-teaching-quality/',
  },
  'ai-learning-web': {
    'webDomain': 'https://aixue.readboy.com/ai-learn-pc/'
  },
  'exam-room-arrangement-web':{
    'domain':'https://ebag.readboy.com/exam-room-arrangement/'
  },
};
