export default {
  path: '/MainPage/personalizedTeaching',
  component: () => import('./PersonalizedTeaching.vue'),
  redirect: {
    name: 'personalizedPrecisionTeaching',
  },
  children: [
    // {
    //   name: 'wrongQuestionBook',
    //   path: 'wrongQuestionBook',
    //   component: () => import('./wrong-question-book/WrongQuestionBook.vue'),
    // },
    {
      path: 'personalizedPrecisionTeaching',
      name: 'personalizedPrecisionTeaching',
      redirect: {
        name: 'LayerPage',
      },
      component: () =>
        import('./PersonalizedPrecisionTeaching.vue'),
      children: [
        // 制定分层方案
        {
          name: 'LayerPage',
          path: 'layerPage',
          component: () => import('./layer-page/LayerPage.vue'),
        },
        // 变式题管理
        {
          name: 'variationQuestionManager',
          path: 'variationQuestionManager',
          component: () => import('./variation-question-manager/VariationQuestionManager.vue'),
        },
        // {
        //   name: 'wrongQuestionManager',
        //   path: 'wrongQuestionManager',
        //   component: () => import('./personalized-precision-teaching/WrongQuestionManager.vue'),
        // },
      ],
    },
    {
      name: 'reportRecords',
      path: 'reportRecords',
      component: () => import('./report-records/ReportRecords.vue'),
      redirect: {
        name: 'RecordList',
      },
      children: [
        {
          name: 'RecordList',
          path: 'recordList',
          component: () => import('./report-records/RecordList.vue'),
        },
        {
          name: 'RecordInfo',
          path: 'recordInfo',
          component: () => import('./report-records/RecordInfo.vue'),
        }
      ]
    },
  ],
};
