<template>
  <div class="loadingContainer">
    <div class="loadingBox" v-show="loadingShow">
      <span
        class="loadingBack"
        v-for="(item,index) in rotatingObj"
        :key="index"
        :style="`transform:rotate(${item*index}deg)`"
      ></span>
      <span class="loadingBlock"></span>
      <span class="loadingMove" :style="`transform:rotate(${angle}deg)`"></span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["area"],
  data() {
    return {
      rotatingObj: null,
      angle: 0,
      loadingShow: false
    };
  },
  created() {
    this.rotatingObj = Array(12).fill(30);
    setInterval(() => {
      this.angle += 30;
      if (this.angle === 1000) {
        this.angle = 0;
      }
    }, 70);
  },
  watch: {
    area() {
      document.getElementsByClassName(
        "loadingContainer"
      )[0].style.cssText = `width:${this.area.w};height:${this.area.h}`;
      this.loadingShow = this.area.show;
    }
  },
  mounted() {
    setTimeout(() => {
      document.getElementsByClassName(
        "loadingContainer"
      )[0].style.cssText = `width:${this.area.w};height:${this.area.h}`;
      this.loadingShow = this.area.show;
    }, 0);
  }
};
</script>

<style lang="less" scoped>
.loadingContainer {
  background-color: #fff;
  position: absolute;
  z-index: 999;
}

.loadingBox {
  width: 64px;
  height: 64px;
  margin: 100px auto;
  background-color: #fff;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingBack {
  width: 54px;
  height: 4px;
  border-radius: 2px;
  display: block;
  background: #c7c7c7;
  position: absolute;
  z-index: 9;
}

.loadingBlock {
  display: block;
  width: 26px;
  height: 26px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  z-index: 12;
}

.loadingMove {
  width: 27px;
  height: 4px;
  border-radius: 2px;
  display: block;
  background: #656565;
  z-index: 11;
  position: absolute;
  left: 32px;
  top: 30px;
  transform-origin: 0 2px;
}
</style>
