export default {
  path: 'helpCenter',
  name: 'HelpCenter',
  component: () => import('@/modules/home-page/HelpCenter.vue'),
  children: [
    {
      path: '',
      redirect: 'helpManual',
    },
    {
      path: 'helpManual',
      name: 'HelpManual',
      component: () => import('@/modules/home-page/helper-views/introductory-guide/HelpManual.vue'),
    },
    {
      path: 'initiationVideo',
      name: 'InitiationVideo',
      component: () => import('@/modules/home-page/helper-views/introductory-guide/InitiationVideo.vue'),
    },
    {
      path: 'helperDownloadCenter',
      name: 'HelperDownloadCenter',
      component: () => import('@/modules/home-page/helper-views/download-center/HelperDownloadCenter.vue'),
    },
    {
      path: 'helperUpdateDescription',
      name: 'HelperUpdateDescription',
      component: () => import('@/modules/home-page/helper-views/update-description/HelperUpdateDescription.vue'),
    },
    {
      path: 'helperAnswerSheetsCenter/:id',
      name: 'HelperAnswerSheetsCenter',
      component: () => import('@/modules/home-page/helper-views/make-answer-sheets/HelperAnswerSheetsCenter'),
    },
    {
      path: 'helperOnlineAnswerSheet/:id',
      name: 'HelperOnlineAnswerSheet',
      component: () => import('@/modules/home-page/helper-views/make-answer-sheets/HelperOnlineAnswerSheet'),
    },
    {
      path: 'helperOfflineAnswerSheet/:id',
      name: 'HelperOfflineAnswerSheet',
      component: () => import('@/modules/home-page/helper-views/make-answer-sheets/HelperOfflineAnswerSheet'),
    },
    {
      path: 'helperTripartiteAnswerSheet/:id',
      name: 'HelperTripartiteAnswerSheet',
      component: () => import('@/modules/home-page/helper-views/make-answer-sheets/HelperTripartiteAnswerSheet'),
    },
    {
      path: 'helperAnswerSheetRequired/:id',
      name: 'HelperAnswerSheetRequired',
      component: () => import('@/modules/home-page/helper-views/make-answer-sheets/HelperAnswerSheetRequired'),
    },
    {
      path: 'helperCreateExam/:id',
      name: 'HelperCreateExam',
      component: () => import('@/modules/home-page/helper-views/examination-management/HelperCreateExam.vue'),
    },
    {
      path: 'helperAffairManage/:id',
      name: 'HelperAffairManage',
      component: () => import('@/modules/home-page/helper-views/examination-management/HelperAffairManage.vue'),
    },
    {
      path: 'helperSheetSet/:id',
      name: 'HelperSheetSet',
      component: () => import('@/modules/home-page/helper-views/examination-management/HelperSheetSet.vue'),
    },
    {
      path: 'helperMarkingTask/:id',
      name: 'HelperMarkingTask',
      component: () => import('@/modules/home-page/helper-views/examination-management/HelperMarkingTask.vue'),
    },
    {
      path: 'helperScanningClient/:id',
      name: 'HelperScanningClient',
      component: () => import('@/modules/home-page/helper-views/examination-management/HelperScanningClient.vue'),
    },
    {
      path: 'helperScannerHardware/:id',
      name: 'HelperScannerHardware',
      component: () => import('@/modules/home-page/helper-views/examination-management/HelperScannerHardware.vue'),
    },
    {
      path: 'helperScanningCommonProblem/:id',
      name: 'HelperScanningCommonProblem',
      component: () => import('@/modules/home-page/helper-views/examination-management/HelperScanningCommonProblem.vue'),
    },
    {
      path: 'helperMarking/:id',
      name: 'HelperMarking',
      component: () => import('@/modules/home-page/helper-views/examination-management/HelperMarking.vue'),
    },
    {
      path: 'helperScoreReport/:id',
      name: 'HelperScoreReport',
      component: () => import('@/modules/home-page/helper-views/examination-management/HelperScoreReport.vue'),
    },
    {
      path: 'helperPaper/:id',
      name: 'HelperPaper',
      component: () => import('@/modules/home-page/helper-views/examination-management/HelperPaper.vue'),
    },
    {
      path: 'helperWebMarking/:id',
      name: 'HelperWebMarking',
      component: () => import('@/modules/home-page/helper-views/examination-marking/HelperWebMarking.vue'),
    },
    {
      path: 'helperMarkingCommonProblem/:id',
      name: 'HelperMarkingCommonProblem',
      component: () => import('@/modules/home-page/helper-views/examination-marking/HelperMarkingCommonProblem.vue'),
    },
    {
      path: 'helperPermissionSpecification/:id',
      name: 'HelperPermissionSpecification',
      component: () => import('@/modules/home-page/helper-views/examination-report/HelperPermissionSpecification.vue'),
    },
    {
      path: 'helperReportSetting/:id',
      name: 'HelperReportSetting',
      component: () => import('@/modules/home-page/helper-views/examination-report/HelperReportSetting.vue'),
    },
    {
      path: 'helperAchievementAnalysis/:id',
      name: 'HelperAchievementAnalysis',
      component: () => import('@/modules/home-page/helper-views/examination-report/HelperAchievementAnalysis.vue'),
    },
    {
      path: 'helperIndicatorSpecification/:id',
      name: 'HelperIndicatorSpecification',
      component: () => import('@/modules/home-page/helper-views/examination-report/HelperIndicatorSpecification.vue'),
    },
    {
      path: 'helperReportCommonProblem/:id',
      name: 'HelperReportCommonProblem',
      component: () => import('@/modules/home-page/helper-views/examination-report/HelperReportCommonProblem.vue'),
    },
    {
      path: 'helperBasicSetting/:id',
      name: 'HelperBasicSetting',
      component: () => import('@/modules/home-page/helper-views/basic-setting/HelperBasicSetting.vue'),
    },
    {
      path: 'helperPersonalInfo/:id',
      name: 'HelperPersonalInfo',
      component: () => import('@/modules/home-page/helper-views/member-information/HelperPersonalInfo.vue'),
    },
  ]
}
