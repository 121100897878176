export default {
  namespaced: true,
  state: {
    commonIndex: 0,
  },
  getters: {},
  mutations: {
    SET_COMMON_INDEX(state, payload) {
      state.commonIndex = payload.value;
    },
  },
};
