// 教辅管理中的题目类型
const QUESTION_MAP = {
  1: {
    name: '单选题',
    view: 'Choice',
    editor: 'SingleChoice',
    children: false
  },
  2: {
    name: '多选题',
    view: 'Choice',
    editor: 'MultipleChoice',
    children: false
  },
  3: {
    name: '填空题',
    view: 'GapFilling',
    editor: 'GapFilling',
    children: false
  },
  4: {
    name: '解答题',
    view: 'Subjective',
    editor: 'Subjective',
    children: false
  },
  13: {
    name: '判断题',
    view: 'Choice',
    editor: 'TrueFalse',
    children: false
  },
  14: {
    name: '连线题',
    view: 'Matching',
    editor: 'Matching',
    // editor: 'Matching',
    children: false
  },
}


// 错题本中的题目类型(从试卷库引用过来的题目)
const WRONG_QUESTION_MAP = {
  10001: {
    name: '单选题',
    view: 'Choice',
    children: false
  },
  10002: {
    name: '多选题',
    view: 'Choice',
    children: false
  },
  10004: {
    name: '判断题',
    view: 'Choice',
    children: false
  },
  10005: {
    name: '填空题',
    view: 'GapFilling',
    children: false
  },
  10007: {
    name: '解答题',
    view: 'Subjective',
    children: false
  },
  10009: {
    name: '连线题',
    view: 'Matching',
    children: false
  },
  100046: {
    name: '综合题',
    view: 'Subjective',
    children: true
  },
}

// 填空题默认空格
const BLANK_WIDTH = 18

const DIFFICULTY_MAP = {
  4: '简单',
  6: '一般',
  8: '困难'
}

const QUESTION_TYPE_MAP = {}
Object.keys(QUESTION_MAP).forEach(key => {
  QUESTION_TYPE_MAP[key] = QUESTION_MAP[key].name
})

const QUESTION_VIEW_MAP = {}
Object.keys(QUESTION_MAP).forEach(key => {
  QUESTION_VIEW_MAP[key] = QUESTION_MAP[key].view
})

const SALT = 'c7bbd6f8356b46ad0e3b83b75c74eec7'

// setlist中包含多种题型的set的type属性
const ALL_TYPE = 0

// 区分登录的用户角色, 目前只有教研人员和普通老师
const USER_ROLE = {
  'ADMIN': "8",
  'TEACHER': "",
}

const PREVIEW_SCORE_LIST = [
  {
    label: "平均分",
    prop: "total_avg",
    backgroundColorList: ["#EBEDFF", "#F3F5FE"],
  },
  {
    label: "最高分",
    prop: "max_score",
    backgroundColorList: ["#F3FAFF", "#E5F4FF"],
  },
  {
    label: "客观题平均分",
    prop: "object_avg",
    backgroundColorList: ["#FDF1E8", "#FEF8F4"],
  },
  {
    label: "主观题平均分",
    prop: "block_avg",
    backgroundColorList: ["#F0FBFF", "#E7F8FF"],
  },
]

const PREVIEW_ALARM_LIST = [
  {
    label: "分数异常",
    prop: "alarmCount",
    backgroundColorList: ["#FFDDDD", "#FEF5F6"],
  },
  {
    label: "成绩波动异常",
    prop: "riseAndFallCount",
    backgroundColorList: ["#FFEECC", "#FFFCEC"],
  },
]

const PREVIEW_STU_COLUMNS = [
  { label: "学校", prop: "SchoolName", width: "180px", fixed: true },
  { label: "姓名", prop: "StudentName", width: "90px", fixed: true },
  { label: "学号", prop: "StudentNo", width: "110px", fixed: true },
  { label: "班级", prop: "ClassName", width: "110px", fixed: true },
  { label: "总分", prop: "TotalScore", width: "100px" },
  { label: "客观题得分", prop: "ObjectScore", width: "100px" },
  { label: "主观题得分", prop: "BlockScore", width: "100px" },
  { label: "状态", prop: "Status", width: "100px" },
]

const PREVIEW_ALARM_LABELS = [
  { label: "非缺考，总分0分" },
  { label: "总分满分" },
  { label: "客观题0分，主观题有分" },
  { label: "客观题有分，主观题0分" },
  { label: "客观题低分，主观题高分", tips: "客观题得分率30%以下；主观题得分60%以上" },
  { label: "客观题高分，主观题低分", tips: "客观题得分率60%以上；主观题得分30%以下" },
]

// 角色对应需要过滤的一级导航
// 没写的角色就代表所有导航都能看到
const ROUTE_FILTER_MAP = {
  '备课组长': ['考试管理', '人员信息'],
  '科组长': ['考试管理', '人员信息'],
  '班主任': ['考试管理'],
  '科任老师': ['考试管理', '人员信息'],
  '年级主任': ['考试管理'],
  '阅卷老师': ['首页', '考试管理', '考试报表', '学情分析', '选题组卷', '人员信息'],
  '阅卷管理员': ['学情分析', '选题组卷', '人员信息'],
  '区域管理员': ['学情分析', '选题组卷', '人员信息'],
  '教研员': ['学情分析', '选题组卷', '人员信息'],
  '教研组长': ['学情分析', '选题组卷', '人员信息'],
}

// 可以进行报表设置的角色
const REPORT_SETTING_MAP = ["校务管理员", "校长", "副校长", "教务主任", '阅卷管理员', '区域管理员']

// 可能可以查看 '考试管理' 的角色
const LIMIT_EXAM_OVERVIEW_ROLE_LIST = ['年级主任', '科组长', '备课组长', '班主任', '科任老师']

// 可以查看"教学质量评价"的角色
const LIMIT_TEACHING_QUALITY_ROLE_LIST = ['年级主任', '教务主任', '校长', '副校长', '校务管理员']

const JOINT_ROLE_LIST = ['阅卷管理员', '区域管理员', '教研员', '教研组长'];

// 等级赋分默认值
const DEFAULT_ASSIGNMENT = [
  {
    rank: 'A',
    ratio: 17,
    sum_ratio: 17,
    score: 0,
    left: 83,
    right: 100,
    actual_gap_left: 0,
    actual_gap_right: 0,
  },
  {
    rank: 'B',
    ratio: 33,
    sum_ratio: 50,
    score: 0,
    left: 71,
    right: 82,
    actual_gap_left: 0,
    actual_gap_right: 0,
  },
  {
    rank: 'C',
    ratio: 33,
    sum_ratio: 83,
    score: 0,
    left: 59,
    right: 70,
    actual_gap_left: 0,
    actual_gap_right: 0,
  },
  {
    rank: 'D',
    ratio: 15,
    sum_ratio: 98,
    score: 0,
    left: 41,
    right: 58,
    actual_gap_left: 0,
    actual_gap_right: 0,
  },
  {
    rank: 'E',
    ratio: 2,
    sum_ratio: 100,
    score: 0,
    left: 30,
    right: 40,
    actual_gap_left: 0,
    actual_gap_right: 0,
  },
]

// 评卷监管部分功能需要屏蔽的角色
const MARKING_HIDDEN_ROLE_LIST = ['科任老师', '班主任', '备课组长', '年级主任', '科组长', '阅卷老师'];

// 因数据过期而禁用的报表参数名
const DISABLED_STALE_DATA_LIST = [
  '班级成绩对比',
  '班级对比数据',
  '科目对比数据',
  '教学分析',
  '学生作答分析'
]

const CAN_SEE_PAPER_WHITE_LIST = {
  account: {
    both: [16600000000, 13075682890, 19892888248, 13597966221],
  },
  schoolName: {
    both: ['中山读书郎实验学校', '西安市西光中学', '成都市郫都区红祥文武学校', '中山市华辰实验中学（小学校区）', '开平市忠源纪念中学',],
    paper: ['珠海市香洲区三溪实验小学', '珠海市香洲区香华实验学校', '珠海市香洲区东桥小学',
      '珠海市香洲区金钟小学',],
    aixue: ['中山市东区中学',"融城中学"]
  },
}

const CAN_SEE_PERSONALIZED_TEACHING_LIST = {
  schoolName: ['西安市黄河中学', '西安市西光中学', '西安市华山中学', '西安市东方中学', '黄冈中学南校区', '读书郎内部中学']
}

// 开放教学质量评价学校
const CAN_SEE_TEACHING_QUALITY_LIST = [1000681, 1000685, 1000684, 1000682, 1000683];

const COURSE_TYPE_OPTION = [
  {
    id: 3103,
    name: '物理类',
    unlessId: 8,
  },
  {
    id: 3104,
    name: '历史类',
    unlessId: 4,
  }
]

const INDICATOR_LIST = [
  {
    "id": 1,
    "name": "平均分"
  },
  {
    "id": 2,
    "name": "优秀率"
  },
  {
    "id": 3,
    "name": "良好率"
  },
  {
    "id": 4,
    "name": "及格率"
  },
  {
    "id": 5,
    "name": "低分率"
  },
  {
    "id": 6,
    "name": "超均率"
  },
  {
    "id": 7,
    "name": "标准差"
  },
  {
    "id": 8,
    "name": "平均位次"
  }
]

const DEFAULT_INDICATORS = [1, 2, 3, 4, 5, 6, 7];

const STATISTIC_INTERGRAL_COLUMN = [
  {
    prop: 'class_name',
    label: '班级',
    affiliationMode: 1,
    width: '150px',
  },
  {
    prop: 'teacher_name',
    label: '班主任',
    width: '150px',
  },
  {
    prop: 'class_name',
    label: '管理班级',
    tooltip: '教师任多个班级时，积分以班级积分总和/班级数计算。',
    affiliationMode: 2,
    width: '150px',
  },
  {
    prop: 'sort',
    label: '积分排名',
    width: '150px',
    chartOption: true,
  },
  {
    prop: 'total_point',
    label: '积分总和',
    tooltip: '时间段内包含多场考试时，积分总和=考试积分总和/考试场数。',
    width: '150px',
    chartOption: true,
  },
  {
    prop: 'avg_point',
    label: '平均分积分',
    width: '150px',
    chartOption: true,
  },
  {
    prop: 'four_ratio_point',
    label: '四率积分总和',
    hasExpand: true,
    expandStatus: false,
    width: '174px',
    chartOption: true,
  },
  {
    prop: 'ranking_point',
    label: '排名积分总和',
    hasExpand: true,
    expandStatus: false,
    width: '174px',
    chartOption: true,
  },
];

const FOUR_RATIO_COLUMN = [
  {
    prop: 'excellent_point',
    label: '优秀率积分',
    width: '110px'
  },
  {
    prop: 'good_ratio_point',
    label: '良好率积分',
    width: '110px'
  },
  {
    prop: 'pass_ratio_point',
    label: '及格率积分',
    width: '110px'
  },
  {
    prop: 'low_ratio_point',
    label: '低分率积分',
    width: '110px'
  },
]

// 开放考场编排学校列表
const CAN_ARRANGE_ROOM_SCHOOL = [1000681, 1000682, 1000683, 1000684, 1000685]

export {
  QUESTION_MAP,
  QUESTION_TYPE_MAP,
  QUESTION_VIEW_MAP,
  DIFFICULTY_MAP,
  SALT,
  ALL_TYPE,
  BLANK_WIDTH,
  USER_ROLE,
  PREVIEW_SCORE_LIST,
  PREVIEW_ALARM_LIST,
  PREVIEW_STU_COLUMNS,
  PREVIEW_ALARM_LABELS,
  ROUTE_FILTER_MAP,
  REPORT_SETTING_MAP,
  LIMIT_EXAM_OVERVIEW_ROLE_LIST,
  DEFAULT_ASSIGNMENT,
  JOINT_ROLE_LIST,
  MARKING_HIDDEN_ROLE_LIST,
  DISABLED_STALE_DATA_LIST,
  CAN_SEE_PAPER_WHITE_LIST,
  CAN_SEE_PERSONALIZED_TEACHING_LIST,
  LIMIT_TEACHING_QUALITY_ROLE_LIST,
  CAN_SEE_TEACHING_QUALITY_LIST,
  COURSE_TYPE_OPTION,
  INDICATOR_LIST,
  DEFAULT_INDICATORS,
  STATISTIC_INTERGRAL_COLUMN,
  FOUR_RATIO_COLUMN,
  CAN_ARRANGE_ROOM_SCHOOL,
}
