import domain from '@/scripts/domain';
import ContentTypeEnum from '../instance/contentType';
import { Http } from '../instance/instance';
// 由于是沿用此前项目的接口 所以没有测试地址 只有固定地址

// 默认contentType为 multipart/form-data
const request = new Http(domain['examination-single']['exam-api-domain'], ContentTypeEnum.JSON, {
  onResponse(response) {
    if (response.status === 200) return response;
    return Promise.reject(response);
  },
});

export default {
  getScoreInfo: (params) => {
    return request.get('/v1/result/new/search/list2', params);
  },
  // 获取全部学生列表 考试报表->成绩册->学生列表 不分页
  getStudentList:(params)=>{
    return request.get('/v1/scorereport/high/score_list/student_list', params)
  },

  // 获取小题分学生列表 考试报表->小题->学生列表 不分页
  getScoreDetailStudentList:(params)=>{
    return request.get('/v1/scorereport/exampaper/score-detail/student_list', params)
  }

};
