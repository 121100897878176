import Vue from 'vue';
import Router from 'vue-router';
import ExamManageRoutes from '@/modules/exam-manage/routes';
import ExamReportRoutes from '@/modules/exam-report/routes';
import PaperMarkingRoutes from '@/modules/paper-marking/routes';
import LearningAnalysisRoutes from '@/modules/learning-analysis/routes';
import PersonalizedTeachingRoutes from '@/modules/personalized-teaching/routes'
import ManageCenterRoutes from '@/modules/manage-center/routes';
import AffairManageRoutes from '@/modules/affair-manage/routes';
import ErrorHandler from '@/modules/error-handler/routes';
import HelpCenterRoutes from './HelperCenterRoutes';
import { localStoragePrefix } from '@/scripts/utils';
import store from '../store/index';
import serve from '@/scripts/serve';
import message from '@/scripts/ele-message';
import { JOINT_ROLE_LIST } from '@/scripts/constant';

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: '/',
      redirect: {
        name: 'Login',
      },
    },
    {
      path: '/Login',
      name: 'Login',
      component: () => import('@/modules/login/NewLogin.vue'),
    },
    {
      path: '/ReportSetting',
      name: 'ReportSetting',
      component: () => import('@/modules/report-setting/ReportSetting.vue')
    },
    {
      path: '/MarkingSupervision',
      name: 'MarkingSupervision',
      component: () => import('@/modules/affair-manage/evaluationManage/supervision.vue'),
    },
    {
      path: '/MainPage',
      component: () => import('@/pages/MainPage.vue'),
      children: [
        {
          path: '',
          redirect: 'homePage',
        },
        {
          path: 'homePage',
          name: 'HomePageIndex',
          component: () => import('@/modules/home-page/Index.vue'),
          children: [
            {
              path: '',
              redirect: 'index',
            },
            {
              path: 'index',
              name: 'NewHomePage',
              component: () => import('@/modules/home-page/NewHomePage.vue'),
            },
            HelpCenterRoutes,
          ]
        },
        {
          path: 'answer-sheet-center',
          name: 'answerSheetCenter',
          component: () => import('@/modules/answer-sheet-center/AnswerSheetCenter.vue'),
        },
        // 查分
        {
          path: 'searchScore/:exam_no/:exam_id',
          name: 'SearchScore',
          meta: { hideMainNav: true },
          component: () => import('@/modules/search-score/ViewScore.vue'),
        },
        {
          path: 'teachingSupervision',
          name: 'teachingSupervision',
          component: () => import('@/modules/teaching-supervision/TeachingSupervision.vue'),
        },
        ExamManageRoutes,
        AffairManageRoutes,
        ...ExamReportRoutes,
        PaperMarkingRoutes,
        LearningAnalysisRoutes,
        PersonalizedTeachingRoutes,
        ManageCenterRoutes,
        ErrorHandler,
        {
          path: 'paper',
          name: 'paper',
          component: () => import('@/pages/Paper.vue'),
        },
        {
          path: 'threeYearsQuestions',
          name: 'threeYearsQuestions',
          component: () => import('@/pages/ThreeYearsQuestions.vue'),
        },
        {
          path: 'teachingQualityEvaluation',
          name: 'teachingQualityEvaluation',
          component: () => import('@/modules/teaching-quality-evaluation/TeachingQualityEvaluation.vue'),
        },
      ],
    },
    {
      path: '/marking',
      name: 'marking',
      component: () => import('@/pages/marking.vue'),
    },
    {
      path: '/marking-view',
      name: 'MarkingView',
      component: () => import('@/modules/marking-task/views/MarkingView.vue'),
    },
    {
      path: '/adjust-coord/:examId/:subjectId/:type/:questionId/:isAddingQuestion',
      name: 'AdjustCoord',
      component: () => import("@/modules/adjustPaperCoord/adjustCoord.vue")
    },
    // 下面的路由可能有特殊用途，但是没找到相关文档
    {
      path: '/setDistri',
      name: 'setDistri',
      component: () => import('@/components/markset/setDistri.vue'),
    },
    {
      path: '/back',
      name: 'back',
      component: () => import('@/pages/back.vue'),
    },
    {
      path: '/MarkSet',
      name: 'MarkSet',
      component: () => import('@/pages/MarkSet.vue'),
    },
    {
      path: '/ClassesManager',
      name: 'ClassesManager',
      component: () => import('@/modules/class-page/ClassesManager.vue'),
    },
    {
      path: '/changeAnswer',
      name: 'changeAnswer',
      component: () => import('@/pages/changeAnswer.vue'),
    },
    {
      path: '/changeMarker',
      name: 'changeMarker',
      component: () => import('@/pages/changeMarker.vue'),
    },
    {
      path: '/studentScoresList',
      name: 'studentScoresList',
      component: () => import('@/pages/studentScoresList.vue'),
    },
    {
      path: '/ClassReport',
      name: 'ClassReport',
      component: () => import('@/modules/exam-report/components/class-report/ClassReport.vue'),
    },
    {
      path: '/bindQuestions',
      name: 'bindQuestions',
      component: () => import('@/pages/bindQuestions.vue'),
    },
    {
      path: '/toolsBox',
      component: () => import('@/pages/toolsBox.vue'),
      children: [
        {
          path: '',
          redirect: '',
        },
        {
          path: 'toolsPage',
          name: 'toolsPage',
          component: () => import('@/components/toolsBox/toolsPage.vue'),
        },
      ],
    },
    {
      path: '/toolCheck',
      name: 'toolCheck',
      component: () => import('@/components/unuse/toolCheck.vue'),
    },
  ],
  // 跳转到新路由时开始位置总在顶端
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
});

const HelpCenterNameList = HelpCenterRoutes.children.map(e=>e.name)

router.beforeEach(async (to, from, next) => {
  if (from.meta.keepAlive) {
    const $main = document.querySelector(from.meta.scrollEl);
    const scrollTop = $main ? $main.scrollTop : 0;
    from.meta.scrollTop = scrollTop;
  }
  const { user_id, token, school_id, user_name: userName } = to.query;
  if (user_id) {
    localStorage.setItem(`${localStoragePrefix}user_id`, user_id);
  }
  if (school_id) {
    localStorage.setItem(`${localStoragePrefix}user_school_id`, school_id);
  }
  if (token) {
    localStorage.setItem(`${localStoragePrefix}session_token`, token);
  }

  // 第三方接入
  if (user_id && userName && token) {
    let {
      body: { F_responseNo, F_data: roleList },
    } = await serve.getPermission({
      F_user_id: user_id,
      F_sn: 'mark_sys',
    })
    if (F_responseNo !== 10000 || !roleList.length) {
      message.warning('获取不到该用户的角色信息，请联系后台人员');
      next({
        name: 'Login',
      });
      return;
    }

    const singleRoleList = roleList.filter((e) => !JOINT_ROLE_LIST.includes(e.role));
    if(singleRoleList.length === 0) {
      message.warning('该用户没有单校权限，请联系后台人员');
      next({
        name: 'Login',
      });
      return;
    }

    const { school_list } = singleRoleList[0].permission;
    if(!Array.isArray(school_list) || !school_list.length) {
      message.warning('该用户没有绑定学校，请联系后台人员');
      next({
        name: 'Login',
      });
      return;
    }

    store.commit('user/SET_USER_INFO', { userInfo: {
      userId: user_id,
      sessionId: token,
      userName,
    }});
    store.commit('user/SET_USER_ROLE_LIST', { value: singleRoleList });
    store.commit('user/CHANGE_CURR_USER_ROLE', { value: singleRoleList[0] });
    store.commit('user/SET_USER_SCHOOL', { F_id: school_list[0].F_id, F_name: school_list[0].F_name });
    store.dispatch('base/SET_IS_JOINT', { value: false });
    store.dispatch('base/SET_HAVE_JOINT_ROLES', { value: false });
    next({
      path: '/MainPage/homePage/index'
    })
    return;
  }


  if (from.fullPath === '/' && to.name === 'unfinishList') {
    const {
      userId, userName, userRole, userSchoolId,
    } = to.query;
    if (userId) {
      localStorage.setItem(`${localStoragePrefix}user_id`, userId);
      localStorage.setItem(`${localStoragePrefix}user_name`, userName);
      localStorage.setItem(`${localStoragePrefix}user_school_id`, userSchoolId);
      localStorage.setItem(
        `${localStoragePrefix}type`,
        userRole === '8' ? 999 : 0,
      );
      next();
      return;
    }
  }
  // 给综合服务平台跳转
  if (to.query.from === 'service' && to.path.includes('overview')) {
    localStorage.setItem(`${localStoragePrefix}type`, 999);
  }

  if (to.fullPath.includes('markPaper')) {
    store.commit('updateCurrentRouteName', '考试阅卷');
  } else if (to.fullPath.includes('error-home')) {
    store.commit('updateCurrentRouteName', '异常处理中心');
  }

  const userType = +window.localStorage.getItem(`${localStoragePrefix}type`);
  const userId = localStorage.getItem(`${localStoragePrefix}user_id`);

  // 跳往登录界面/帮助中心不做限制
  if (to.name === 'Login' || HelpCenterNameList.includes(to.name)) {
    next();
    return;
  }

  // 用户没有登陆的时候 即使通过路由输入地址 也要跳转到login界面
  if (
    (typeof userId === 'undefined' || userId === null)
    && to.name !== 'Login'
  ) {
    next({
      name: 'Login',
    });
    return;
  }

  // 当用户不为999 让用户跳到阅卷任务 不跳到阅卷管理
  // if (userType !== 999 && to.name === 'overview') {
  //   next({
  //     name: 'unfinishList',
  //   });
  //   return;
  // }
  next();
});

export default router;
