/**
 * 考试报表模块的路由
 */
export default [
  {
    path: 'testReport',
    component: () => import('./ExamReport.vue'),
    meta: {
      keepAlive: true,    // 标记该路由组件需要被缓存
      scrollEl: '#report-exam-list', // 标记该页面中需记录滚动高度的元素
    },
  },
  {
    path: 'reportPage',
    name: 'reportPage',
    component: () => import('./ReportPage.vue'),
  },
  {
    path: 'reportDetail',
    name: 'reportDetail',
    component: () => import('./ReportDetail.vue'),
    // meta: { hideMainNav: true },
    children: [

      /** 非高中的考试报表子路由 */
      // 学生成绩单
      {
        path: 'student',
        name: 'ReportStudent',
        component: () =>
          import(
            /* webpackChunkName: "score_report" */ './views/student/index.vue'
          ),
      },
      // 成绩统计表
      {
        path: 'area',
        name: 'ReportArea',
        component: () =>
          import(/* webpackChunkName: "score_report" */ './views/area/index.vue'),
      },
      // 分数线
      {
        path: 'grades',
        component: () =>
          import(
            /* webpackChunkName: "score_report" */ './views/grades/index.vue'
          ),
      },
      // 分数段
      // {
      //   path: 'gradeSection',
      //   meta: {
      //     main: false,
      //     authority: false,
      //     platform: 'examination-web',
      //     logoutNoRedirect: true,
      //   },
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "score_report" */  './views/hight/section/index.vue'
      //     ),
      // },
      // 试卷分析
      {
        path: 'paper',
        name: 'reportPaper',
        component: () =>
          import(
            /* webpackChunkName: "score_report" */ './views/paper/index.vue'
          ),
      },
      // 小题平均分
      {
        path: 'little',
        component: () =>
          import(
            /* webpackChunkName: "score_report" */ './views/little/LittleAvg.vue'
          ),
      },
      {
        path: 'oldDownload',
        component: () =>
          import(
            /* webpackChunkName: "score_report" */ './views/hight/download/download.vue'
          ),
      },
      {
        path: 'exportBook',
        component: () =>
          import(
            /* webpackChunkName: "score_report" */ './views/download/GradeBook.vue'
          ),
      },
      {
        path: 'reportPdf',
        component: () =>
          import(
            /* webpackChunkName: "score_report" */ './views/report-pdf/ReportPdf.vue'
          ),
      },



      /** 高中的考试报表子路由 */

      {
        path: 'gradeFrom',
        name: 'gradeFrom',
        meta: { main: true, authority: false, logoutNoRedirect: true },
        component: () =>
          import(
            /* webpackChunkName: "hight_score_report" */ './views/hight/gradeFrom/index.vue'
          ),
      },
      {
        path: 'littleQuestion',
        name: 'littleQuestion',
        meta: { main: true, authority: false, logoutNoRedirect: true },
        component: () =>
          import(
            /* webpackChunkName: "score_report" */ './views/hight/little/index.vue'
          ),
        children: [
          {
            path: '',
            redirect: { path: 'student' },
          },
          {
            path: 'student',
            meta: { main: true, authority: false, logoutNoRedirect: true },
            component: () => import(/* webpackChunkName: "score_report" */'./views/hight/little/studentSmall.vue')
          },
          {
            path: 'choice',
            meta: { main: true, authority: false, logoutNoRedirect: true },
            component: () => import(/* webpackChunkName: "score_report" */'./views/hight/little/choiceStat.vue')
          },
          {
            path: 'detail',
            meta: { main: true, authority: false, logoutNoRedirect: true },
            component: () => import(/* webpackChunkName: "score_report" */'./views/hight/little/scoreDetail.vue')
          },
          {
            path: 'average',
            name: 'LittleAverage',
            meta: { main: true, authority: false, logoutNoRedirect: true },
            component: () => import(/* webpackChunkName: "score_report" */'./views/hight/little/smallAverage.vue')
          },
          {
            path: 'qsDetail',
            name: '小题分表',
            meta: { main: true, authority: false, logoutNoRedirect: true },
            component: () => import(/* webpackChunkName: "score_report" */'./views/paper/paper.vue')
          },
        ]
      },
      {
        path: 'gradeSection',
        meta: { main: true, authority: false, logoutNoRedirect: true },
        component: () =>
          import(
            /* webpackChunkName: "score_report" */ './views/hight/section/index.vue'
          ),
        children: [
          {
            path: '',
            redirect: { path: 'score' },
          },
          {
            path: 'score',
            meta: { main: true, authority: false, logoutNoRedirect: true },
            component: () => import(/* webpackChunkName: "score_report" */'./views/hight/section/scoreSection.vue')
          },
          {
            path: 'rank',
            meta: { main: true, authority: false, logoutNoRedirect: true },
            component: () => import(/* webpackChunkName: "score_report" */'./views/hight/section/rankSection.vue')
          },
          {
            path: 'from',
            meta: { main: true, authority: false, logoutNoRedirect: true },
            component: () => import(/* webpackChunkName: "score_report" */'./views/hight/section/rankFrom.vue')
          }
        ]
      },
      {
        path: 'levelGrades',
        meta: { main: true, authority: false, logoutNoRedirect: true },
        component: () =>
          import(
            /* webpackChunkName: "score_report" */ './views/hight/level/LevelGrades.vue'
          ),
        children: [
          {
            path: '',
            redirect: { path: 'student' },
          },
          {
            path: 'student',
            meta: { main: true, authority: false, logoutNoRedirect: true },
            component: () => import(/* webpackChunkName: "score_report" */'./views/hight/level/StudentLevel.vue')
          },
          {
            path: 'unit',
            meta: { main: true, authority: false, logoutNoRedirect: true },
            component: () => import(/* webpackChunkName: "score_report" */'./views/hight/level/UnitLevel.vue')
          },
        ]
      },
      {
        path: 'basicAnalysis',
        meta: { main: true, authority: false, logoutNoRedirect: true },
        component: () =>
          import(
            /* webpackChunkName: "score_report" */ './views/hight/basic/index.vue'
          ),
      },
      {
        path: 'lineAnalysis',
        meta: { main: true, authority: false, logoutNoRedirect: true },
        component: () =>
          import(
            /* webpackChunkName: "score_report" */ './views/hight/line/LineAnalysis.vue'
          ),
        children: [
          {
            path: '',
            redirect: { path: 'type1' },
          },
          {
            path: 'type1',
            meta: { main: true, authority: false, logoutNoRedirect: true },
            component: () => import(/* webpackChunkName: "score_report" */'./views/hight/line/TypeOne.vue')
          },
          {
            path: 'type2',
            meta: { main: true, authority: false, logoutNoRedirect: true },
            component: () => import(/* webpackChunkName: "score_report" */'./views/hight/line/TypeTwo.vue')
          },
          {
            path: 'overview',
            meta: { main: true, authority: false, logoutNoRedirect: true },
            component: () => import(/* webpackChunkName: "score_report" */'./views/hight/line/Overview.vue')
          },
        ]
      },
      {
        path: 'concernedStudent',
        meta: { main: true, authority: false, logoutNoRedirect: true },
        component: () =>
          import(
            /* webpackChunkName: "score_report" */ './views/hight/concern/Concern.vue'
          ),
        children: [
          {
            path: '',
            redirect: { path: 'excellent' },
          },
          {
            path: 'excellent',
            meta: { main: true, authority: false, logoutNoRedirect: true },
            component: () => import(/* webpackChunkName: "score_report" */'./views/hight/concern/ExcellentStudent.vue')
          },
          {
            path: 'critical',
            meta: { main: true, authority: false, logoutNoRedirect: true },
            component: () => import(/* webpackChunkName: "score_report" */'./views/hight/concern/CriticalStudent.vue')
          },
        ]
      },

      {
        path: 'download',
        meta: { main: true, authority: false, logoutNoRedirect: true },
        component: () =>
          import(
            /* webpackChunkName: "score_report" */ './views/hight/download/download.vue'
          ),
      },

      /** 高中和非高中都有的子路由 */
      // 试卷评讲
      {
        path: 'review',
        name: 'review',
        meta: { main: true, authority: false, logoutNoRedirect: true },
        component: () =>
          import(
            /* webpackChunkName: "score_report" */ './components/PaperReview.vue'
          ),
      },
    ]
  }
];
