export default {
  path: 'learningCondition',
  name: 'learningCondition',
  component: () => import('./LearningCondition.vue'),
  children: [
    {
      path: '',
      redirect: 'subjectConditionRouter',
    },
    {
      path: 'subjectConditionRouter',
      name: 'subjectConditionRouter',
      component: () => import('./subject-analysis/SubjectConditionRouter.vue'),
      children: [
        {
          path: '',
          redirect: 'subjuetCondition',
        },
        {
          path: 'conditionInfo',
          name: 'conditionInfo',
          component: () => import('./subject-analysis/views/SubjectConditionInfo.vue'),
          meta: { keepStatus: false },
        },
        {
          path: 'subjuetCondition',
          name: 'subjuetCondition',
          component: () => import('./subject-analysis/views/SubjectCondition.vue'),
          meta: { keepStatus: true },
        },
      ],
    },
    {
      path: 'studentCondition',
      name: 'studentCondition',
      component: () => import('./student-analysis/StudentConditionRouter.vue'),
      children: [
        {
          path: '',
          redirect: 'studentLiteracyIndex',
        },
        {
          path: 'studentLiteracyIndex',
          name: 'studentLiteracyIndex',
          component: () => import('./student-analysis/views/StudentLiteracyIndex.vue'),
          meta: { keepStatus: true },
        },
        {
          path: 'studentCase',
          name: 'studentCase',
          component: () => import('./student-analysis/views/StudentCase.vue'),
          meta: { keepStatus: false },
        },
      ],
    },
    {
      path: 'schoolWorkCondition',
      name: 'schoolWorkCondition',
      component: () => import('./study-analysis/SchoolWorkCondition.vue'),
    },
    {
      path: 'studyBigData',
      name: 'studyBigData',
      component: () => import('./study-bigdata/StudyBigData.vue'),
    },
  ],
};
