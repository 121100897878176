/**
 *  vuex 的全局基础信息模块
 */
import { getFilterData } from '@/modules/affair-manage/getFilterData';

const base = {
  namespaced: true,
  state: () => {
    return {
      isJoint: false,
      haveJointRoles: false,
      examDistricts: [], // 市区学校级联选择器数据
      sysVersionData: JSON.parse(localStorage.getItem('sysVersionData')) ?? null, // 当前系统更新版本
    };
  },
  getters: {
    examCityOptions(state) {
      return state.examDistricts.map((e) => ({ ...e }));
    },
    examCountyOptions(state, getters) {
      let countyOptions = [];
      getters.examCityOptions.forEach((e) => (countyOptions = countyOptions.concat(e.children)));
      return countyOptions;
    },
    examSchoolOptions(state, getters) {
      let schoolOptions = [];
      getters.examCountyOptions.forEach((e) => (schoolOptions = schoolOptions.concat(e.children)));
      return schoolOptions;
    },
  },
  mutations: {
    SET_EXAM_DISTRICT(state, payload) {
      state.examDistricts = payload.examDistricts;
    },
    SET_SYS_VERSION_DATA(state, payload) {
      state.sysVersionData = payload.sysVersionData;
      localStorage.setItem('sysVersionData', JSON.stringify(payload.sysVersionData));
    },
  },
  actions: {
    SET_IS_JOINT({ state, commit }, payload) {
      state.isJoint = payload.value;
    },
    SET_HAVE_JOINT_ROLES({ state }, payload) {
      state.haveJointRoles = payload.value;
    },
    async GET_EXAM_DISTRICT({ state, commit }, { exam_id, user_permission, marker_id }) {
      const examDistricts = await getFilterData(exam_id, user_permission, marker_id);
      commit('SET_EXAM_DISTRICT', { examDistricts });
    },
  },
};

export default base;
