export default {
  path: 'AffairManage/:examId',
  name: 'AffairManage',
  component: () => import('./AffairManage.vue'),
  props: true,
  children: [
    {
      path: '',
      redirect: 'studentManage'
    },
    {
      path: 'studentManage',
      name: 'studentManage',
      meta: { label: '考生管理' },
      component: () => import('./student-manage/studentManage.vue'),
    },
    {
      path: 'staffManage',
      name: 'staffManage',
      meta: { label: '阅卷员管理' },
      component: () => import('./staff-manage/staffManage.vue'),
    },
    {
      path: 'answer-sheet-manage',
      name: 'innerAnswerSheetManage',
      meta: { label: '答题卡管理' },
      component: () => import('./answer-sheet-manage/AnswerSheetManage.vue'),
    },
    // {
    //   path: 'markingTask',
    //   name: 'markingTask',
    //   meta: { label: '阅卷任务' },
    //   component: () => import('./marking-task/markingTask.vue'),
    // },
    // {
    //   path: 'evaluationManage',
    //   name: 'evaluationManage',
    //   meta: { label: '评卷监管' },
    //   component: () => import('./evaluationManage/evaluationManageHome.vue'),
    // },
  ],
};
