// import Vue from "vue";
const schoolConfig = {
  namespaced: true,
  state: () => {
    return {
      authorityConfig: null,
      isLimit: false,
      settingKey: '',
    };
  },
  getters: {
    isMarkSupervision: (state) => {
      return state.settingKey === 'mark_supervision'
    }
  },
  mutations: {
    SET_IS_LIMIT(state, payload) {
      state.isLimit = payload.value;
    },
    SET_KEY(state, payload) {
      state.settingKey = payload.value;
    },
  },
  actions: {
    async SET_AUTHORITY_CONFIG({ state }, payload) {
      state.authorityConfig = payload.value;
    },
  },
};

export default schoolConfig;
