/***
 *  《考试阅卷》模块下面的路由表
 */
export default {
  path: 'markPaper',
  component: () => import('./MarkPaper.vue'),
  children: [
    {
      path: '',
      redirect: 'markPage',
    },
    {
      path: 'markPage',
      component: () => import('@/modules/marking-task/MarkPage.vue'),
      children: [
        {
          path: '',
          redirect: 'unfinishList',
        },
        {
          path: 'unfinishList',
          name: 'unfinishList',
          component: () => import('@/modules/marking-task/views/UnfinishList.vue'),
        },
        {
          path: 'arbitration-home/:mode',
          name: 'arbitrationHome',
          props: true,
          component: () => import(
            '@/modules/paper-marking/evaluation-regulation/views/evaluation-regulation-list.vue'
          ),
        },
        {
          path: 'arbitration-marking',
          name: 'arbitrationMarking',
          component: () => import(
            '@/modules/paper-marking/evaluation-regulation/views/evaluation-regulation-arbitration-marking.vue'
          ),
        },
      ],
    },
    {
      path: 'evaluationRegulationHome',
      name: 'evaluationRegulationHome',
      component: () => import('./evaluation-regulation/evaluation-regulation-home.vue'),
      children: [
        {
          path: '',
          redirect: 'evaluationRegulationList/1',
        },
        {
          path: 'evaluationRegulationList/:mode',
          name: 'evaluationRegulationList',
          props: true,
          component: () => import('./evaluation-regulation/views/evaluation-regulation-list.vue'),
        },
        {
          name: 'evaluationRegulationMarking',
          path: 'evaluationRegulationMarking',
          component: () => import('./evaluation-regulation/views/evaluation-regulation-marking.vue'),
        },
      ],
    },
  ],
};
