<template>
  <el-button
    :class="buttonClass"
    :plain="plain"
    :type="buttonType"
    :round="round"
    :size="size"
    :disabled="disabled"
    :native-type="type"
    @click="$emit('click', $event)"
    ><slot
  /></el-button>
</template>
<script>
export default {
  name: 'RbButton',
  props: {
    warn: { type: Boolean, default: false },
    round: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    block: { type: Boolean, default: false },
    plain: { type: Boolean, default: false },
    size: { default: 'large' },
    type: {},
  },
  computed: {
    buttonClass() {
      return {
        'rb-button': true,
        'rb-button--block': this.block,
      };
    },
    buttonType() {
      if (this.warn) {
        return 'warning';
      }
      return 'primary';
    },
  },
};
</script>
<style lang="less" scoped>
::v-deep.el-button.rb-button {
  &.el-button--primary:not(.is-disabled) {
    background: #009cff;
    &:hover {
      background: rgba(0, 156, 255, 0.8);
    }
    &:active {
      background: #009cff;
    }
    &.is-plain {
      border: 1px solid #339efc;
      color: #339efc;
      background: #fff;
      &:hover {
        background: #f7fbff;
      }
      &:active {
        background: #fff;
      }
    }
  }
  &.el-button--warning:not(.is-disabled) {
    background: #f39c11;
    &:hover {
      background: rgba(243, 156, 17, 0.8);
    }
    &:active {
      background: #f39c11;
    }
    &.is-plain {
      border: 1px solid #f39c11;
      color: #f39c11;
      background: #fff;
      &:hover {
        background: #f8f5f1;
      }
      &:active {
        background: #fff;
      }
    }
  }
  &.el-button--primary:not(.is-disabled) {
    background: #009cff;
    &:hover {
      background: rgba(0, 156, 255, 0.8);
    }
    &:active {
      background: #009cff;
    }
    &.is-plain {
      border: 1px solid #339efc;
      color: #339efc;
      background: #fff;
      &:hover {
        background: #f7fbff;
      }
      &:active {
        background: #fff;
      }
    }
  }

  &.is-disabled {
    background: #d9d9d9;
    border: 1px solid #d9d9d9;
    color: #ffffff;
    &:active,
    &:hover {
      background: #d9d9d9;
    }
  }

  &.rb-button--block {
    width: 100%;
  }
  &.el-button--large {
    height: 44px;
    font-size: 15px;
  }
  &.is-round {
    border-radius: 26px;
  }
}
</style>
