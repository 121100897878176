// 解析科目内容
// 全局科目映射表，仅维护这里
let analyzeSubjectMap = {
  1: '语文',
  2: '数学',
  3: '英语',
  4: '物理',
  5: '化学',
  6: '生物',
  7: '政治',
  8: '历史',
  9: '地理',
  10: '科学',
  11: '综合',
  14: '美术',
  15: '音乐',
  70: '日语',
  79: '其他',
  44: '理综',
  88: '文综',
};
const updateSubjectMap = (map) => {
  analyzeSubjectMap = map;
}

// 解析学段
const analyzeStageMap = {
  xiaoxue: '小学',
  chuzhong: '初中',
  gaozhong: '高中',
};

// 解析年级内容
const analyzeGradeMap = {
  1: {
    name: '一年级',
    stage: 1,
    stageName: '小学',
    gradeId: 1,
  },
  2: {
    name: '二年级',
    stage: 1,
    stageName: '小学',
    gradeId: 2,
  },
  3: {
    name: '三年级',
    stage: 1,
    stageName: '小学',
    gradeId: 3,
  },
  4: {
    name: '四年级',
    stage: 1,
    stageName: '小学',
    gradeId: 4,
  },
  5: {
    name: '五年级',
    stage: 1,
    stageName: '小学',
    gradeId: 5,
  },
  6: {
    name: '六年级',
    stage: 1,
    stageName: '小学',
    gradeId: 6,
  },
  7: {
    name: '七年级',
    stage: 2,
    stageName: '初中',
    gradeId: 7,
  },
  8: {
    name: '八年级',
    stage: 2,
    stageName: '初中',
    gradeId: 8,
  },
  9: {
    name: '九年级',
    stage: 2,
    stageName: '初中',
    gradeId: 9,
  },
  10: {
    name: '高一',
    stage: 3,
    stageName: '高中',
    gradeId: 10,
  },
  11: {
    name: '高二',
    stage: 3,
    stageName: '高中',
    gradeId: 11,
  },
  12: {
    name: '高三',
    stage: 3,
    stageName: '高中',
    gradeId: 12,
  },
  101: {
    name: '小学毕业班',
    stage: 1,
    stageName: '小学',
    gradeId: 101,
  },
  102: {
    name: '初中毕业班',
    stage: 2,
    stageName: '初中',
    gradeId: 102,
  },
  103: {
    name: '高中毕业班',
    stage: 3,
    stageName: '高中',
    gradeId: 103,
  },
};

// 解析年级内容
// const analyzeGradeMap2 = {
//   1: "一年级",
//   2: "二年级",
//   3: "三年级",
//   4: "四年级",
//   5: "五年级",
//   6: "六年级",
//   7: "七年级",
//   8: "八年级",
//   9: "九年级"
// };

// 解析考试当前步骤状态
const analyzeStepMap = {
  1: '答题卡设置',
  2: '阅卷设置',
  3: '扫描阅卷',
  4: '批改阅卷',
  5: '异常核查',
  6: '成绩统计',
  7: '无数据',
};

// 解析handleProcess目录流程展开阶段
const analyzeIndexMap = {
  1: 0,
  2: 0,
  3: 1,
  4: 1,
  5: 1,
  6: 2,
};

// 解析workProcess目录流程展开阶段
const analyzeWorkIndexMap = {
  1: 0,
  2: 0,
  3: 0,
  4: 0,
  5: 0,
  6: 1,
};

const analyzeStatusMap = {
  0: '正常',
  1: '缺考',
  2: '作弊',
};

const NumberToChinese = {
  1: '一',
  2: '二',
  3: '三',
  4: '四',
  5: '五',
  6: '六',
  7: '七',
  8: '八',
  9: '九',
  10: '十',
};

const analyzeTypeMap = {
  1: '周考',
  2: '月考',
  3: '期中',
  4: '期末',
  5: '作业',
};

const analyzeExamMode = {
  1: '线上阅卷',
  2: '线下阅卷',
  3: '在线考试',
  4: '成绩导入',
  6: '线上阅卷+电子原卷',
};

const analyzeWorkMode = {
  1: '线上批改',
  2: '纸笔批改',
  // 3: '在线考试',
  // 4: '成绩导入'
};

const type_id = {
  unknown: 4,
  选择题: 1,
  多选题: 2,
  填空题: 3,
  解答题: 4,
  完形填空: 5,
  阅读理解: 6,
  英语作文: 7,
  选词填空题: 8,
  综合题: 9,
  语文阅读题: 10,
  语文作文: 11,
  英语匹配题: 12,
  判断题: 13,
  连线题: 14,
  排序题: 15,
  连词成句: 16,
  翻译题: 17,
  主观填空题: 18,
  自定义选择题: 99
};

const questionTypeMap = {
  1: '单选题',
  2: '多选题',
  3: '填空题',
  4: '解答题',
  5: '完形填空',
  6: '阅读理解',
  7: '英语作文题',
  8: '选词填空题',
  9: '综合题',
  10: '语文阅读题',
  11: '语文作文题',
  12: '英语匹配题',
  13: '判断题',
  14: '连线题',
  15: '排序题',
  16: '连词成句',
  17: '翻译题',
  18: '主观填空题',
  99: '自定义选择题'
};

const CHOOSE_PAGE_QUESTIONS_TYPE = [1, 2, 13, 99];
const OBJECT_PAGE_QUESTIONS_TYPE = [3];

// 王彦侠：0-2019-0-bbcbb2e0-bdbf-4f0a-8ab0-3376b586ce7e
// 何慧兰：0-2019-0-06d39c43-fe10-37d9-a8f8-6dff76b2a726
// 李干泉：0-2019-0-812d1574-17c1-4b5d-b29b-e8b05de057e5
// 肖慈英：0-2019-0-5c351a09-9398-4273-bbcc-fe60d4d1235c
// 吴毅强：0-2019-0-cab00a38-dca3-48ea-8654-f935fd5e79a3
const MERGE_IMGS_USERIDS = [
  '0-2019-0-bbcbb2e0-bdbf-4f0a-8ab0-3376b586ce7e',
  '0-2019-0-06d39c43-fe10-37d9-a8f8-6dff76b2a726',
  '0-2019-0-812d1574-17c1-4b5d-b29b-e8b05de057e5',
  '0-2019-0-5c351a09-9398-4273-bbcc-fe60d4d1235c',
  '0-2019-0-cab00a38-dca3-48ea-8654-f935fd5e79a3',
];

// 某英语老师： 0-2019-0-6888435b-73c8-4368-8fc3-bb89044ad154
// 考试id和学科id: 2005091128541275    3
const asideNavs = [
  {
    title: '准备流程',
    active: false,
    list: [
      {
        attr: 'answerSheetList',
        txt: '答题卡设置',
        status: 1,
      },
      {
        attr: 'set',
        txt: '阅卷设置',
        status: 2,
      },
    ],
  },
  {
    title: '阅卷流程',
    active: false,
    list: [
      {
        attr: 'smartPage',
        txt: '扫描阅卷',
        status: 3,
      },
    ],
  },
  {
    title: '成绩报告',
    active: false,
    list: [
      {
        attr: 'report',
        txt: '成绩统计',
        status: 6,
      },
      {
        attr: 'analyse',
        txt: '得分情况',
        status: 6,
      },
      {
        attr: 'reply',
        txt: '各题分析',
        status: 6,
      },
      {
        attr: 'ranking',
        txt: '成绩分布',
        status: 6,
      },
      {
        attr: 'grade',
        txt: '年级统计',
        status: 6,
      },
      {
        attr: 'paper',
        txt: '考试题目',
        status: 6,
      },
    ],
  },
];

const uploadNavs = [
  {
    title: '准备流程',
    active: false,
    list: [
      {
        attr: 'sheet',
        txt: '设置结构',
        status: 1,
      },
    ],
  },
  {
    title: '阅卷流程',
    active: false,
    list: [
      {
        attr: 'upload',
        txt: '成绩导入',
        status: 3,
      },
    ],
  },
  {
    title: '成绩报告',
    active: false,
    list: [
      {
        attr: 'report',
        txt: '成绩统计',
        status: 6,
      },
      {
        attr: 'analyse',
        txt: '得分情况',
        status: 6,
      },
      {
        attr: 'reply',
        txt: '各题分析',
        status: 6,
      },
      {
        attr: 'ranking',
        txt: '成绩分布',
        status: 6,
      },
      {
        attr: 'grade',
        txt: '年级统计',
        status: 6,
      },
      {
        attr: 'paper',
        txt: '考试题目',
        status: 6,
      },
    ],
  },
];

const uploadAllNavs = [
  {
    title: '阅卷流程',
    active: false,
    list: [
      {
        attr: 'upload',
        txt: '成绩导入',
        status: 3,
      },
    ],
  },
  {
    title: '成绩报告',
    active: false,
    list: [
      {
        attr: 'report',
        txt: '成绩统计',
        status: 6,
      },
      {
        attr: 'analyse',
        txt: '得分情况',
        status: 6,
      },
      {
        attr: 'reply',
        txt: '各题分析',
        status: 6,
      },
      {
        attr: 'ranking',
        txt: '成绩分布',
        status: 6,
      },
      {
        attr: 'grade',
        txt: '年级统计',
        status: 6,
      },
      {
        attr: 'paper',
        txt: '考试题目',
        status: 6,
      },
    ],
  },
];
const workAsideNavs = [
  {
    title: '阅卷流程',
    active: false,
    list: [
      {
        attr: 'smartPage',
        txt: '智能批改',
        status: 3,
      },
      // {
      //   attr: "smartPage",
      //   txt: "批改进度",
      //   status: 3
      // },
    ],
  },
  {
    title: '成绩报告',
    active: false,
    list: [
      {
        attr: 'report',
        txt: '成绩统计',
        status: 6,
      },
      {
        attr: 'analyse',
        txt: '得分情况',
        status: 6,
      },
      {
        attr: 'reply',
        txt: '各题分析',
        status: 6,
      },
      {
        attr: 'ranking',
        txt: '成绩分布',
        status: 6,
      },
      {
        attr: 'grade',
        txt: '年级统计',
        status: 6,
      },
      {
        attr: 'paper',
        txt: '考试题目',
        status: 6,
      },
    ],
  },
];

// 4:"总分导入"（暂无）
const sheetSaveType = {
  1: '自制答题卡',
  2: '导题智能创建',
  3: '在线考试答题卡',
  5: '成绩导入答题卡',
  7: '原卷阅卷答题卡',
};

// echart表格的颜色默认值
const COLORS = [
  '#3fcfde',
  '#008FF5',
  '#00e0e0',
  '#70c27e',
  '#00cc11',
  '#a3f500',
  '#f5f500',
  '#ffa21f',
  '#e05652',
  '#ff3366',
  '#ff70ff',
  '#ac70ff',
  '#5c5cff',
];

const genderMap = {
  1: '男',
  2: '女',
};

const answerMap = {
  0: "A",
  1: "B",
  2: "C",
  3: "D",
  4: "E",
  5: "F",
  6: "G",
  7: "H",
  8: "I",
  9: "J",
  10: "K",
  11: "L",
  12: "M",
  13: "N",
  14: "O",
  15: "P",
  16: "Q",
  17: "R",
  18: "S",
  19: "T",
  20: "U",
  21: "V",
  22: "W",
  23: "X",
  24: "Y",
  25: "Z",
};

// 问题修正界面的tab
const CORRECT_TABS = [
  {
    label: "全部",
    id: 0,
    countKey: "actualResultCountCount",
    paths: ["actualResult", "actualResults"],
  },
  {
    label: "待上传",
    id: 9999,
    countKey: "readyOmrCount",
    paths: ["readyOmrInfo", "results"],
  },
  {
    label: "定位异常",
    id: 4,
    countKey: "exceptionOmrInfoCount",
    paths: ["exceptionOmrInfo", "results"],
  },
  {
    label: "分数异常",
    id: 2,
    countKey: "exceptionScoreInfoCount",
    paths: ["exceptionScoreInfo", "exceptionScore"],
  },
  {
    label: "准考证异常",
    id: 1,
    countKey: "exceptionIDInfoCount",
    paths: ["exceptionIDInfo", "exceptionResults"],
  },
  {
    label: "考号重复",
    id: 3,
    countKey: "exceptionIDSameInfoCount",
    paths: ["exceptionIDSameInfo", "exceptionIdSameSimples"],
  },
  {
    label: "选择题异常",
    id: 7,
    countKey: "exceptionOptionInfoCount",
    paths: ["exceptionOptionInfo", "exceptionResults"],
  },
  {
    label: "缺考管理",
    id: 5,
    countKey: "exceptionAbsenceInfoCount",
    paths: ["exceptionAbsenceInfo", "results"],
  },
  {
    label: "已处理",
    id: 1000,
    countKey: "exceptionSolutionCount",
    paths: ["exceptionSolutionInfo", "results"],
  },
  {
    label: "疑难卷",
    id: 999,
    countKey: "exceptionProblemCount",
    paths: ["exceptionProblemInfo", "results"],
  },
  {
    label: "客观题框选异常",
    id: 10,
    countKey: "exceptionOmrOptionInfoCount",
    paths: ["exceptionOmrOptionInfo", "exceptionResults"],
  },
];

// 问题修正界面的组件map [{id:对应的tab}]
const CORRECT_ID_MAP = {};
CORRECT_TABS.forEach((tab) => {
  CORRECT_ID_MAP[tab.id] = tab;
});

const TrueOrFalseMap = {
  0: "T",
  1: "F",
};

const PAPER_SHEET = [1, 2]; // 纸质答题卡的 save_type 类别
const ONLINE_SHEET = [3, 4, 5, 9]; // 非纸质答题卡的 save_type 类别

const roleName2IdMap = {
  '年级主任': 'grade_master',
  '科组长': 'subject_master',
  '备课组长': 'grade_subject_master',
  '班主任': 'class_master',
  '科任老师': 'class_teacher',
}

const num2alpha = {
  '-1': '未作答',
  0: 'A',
  1: 'B',
  2: 'C',
  3: 'D',
  4: 'E',
  5: 'F',
  6: 'G',
  7: 'H',
  8: 'I',
  9: 'J',
};

const judgeQuestionAnswer = {
  0: '对',
  1: '错',
};

const MARKING_MODE_MAP = {
  0: '单评',
  1: '双评',
  2: '双评',
  3: '三评',
  4: '三评',
}

const MARKING_TYPE = {
  0: '单评',
  1: '双评',
  2: '双评',
  3: '三评含仲裁',
  4: '三评不仲裁',
}

// 学生参与考试科目状态
const PART_IN_SUBJECT_STATUS = {
  'noRecord':-1, // 无记录
  'normal':0, // 正常
  'absent':1, // 缺考
  'noSelect':3 // 非选考科目
}

export {
  updateSubjectMap,
  analyzeSubjectMap,
  analyzeStageMap,
  analyzeGradeMap,
  analyzeStepMap,
  analyzeIndexMap,
  analyzeWorkIndexMap,
  analyzeStatusMap,
  analyzeTypeMap,
  NumberToChinese,
  analyzeExamMode,
  analyzeWorkMode,
  asideNavs,
  workAsideNavs,
  uploadNavs,
  uploadAllNavs,
  type_id,
  questionTypeMap,
  CHOOSE_PAGE_QUESTIONS_TYPE,
  OBJECT_PAGE_QUESTIONS_TYPE,
  MERGE_IMGS_USERIDS,
  PAPER_SHEET,
  ONLINE_SHEET,
  COLORS,
  sheetSaveType,
  genderMap,
  answerMap,
  CORRECT_TABS,
  CORRECT_ID_MAP,
  TrueOrFalseMap,
  roleName2IdMap,
  num2alpha,
  judgeQuestionAnswer,
  MARKING_MODE_MAP,
  MARKING_TYPE,
  PART_IN_SUBJECT_STATUS,
};
