// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import vueResource from 'vue-resource';
import Vuex from 'vuex';
import {
  Autocomplete,
  Button,
  Cascader,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  DatePicker,
  Dialog,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Form,
  FormItem,
  Icon,
  Input,
  InputNumber,
  Loading,
  Option,
  OptionGroup,
  Pagination,
  Popover,
  Popconfirm,
  Progress,
  Radio,
  RadioButton,
  RadioGroup,
  Select,
  Steps,
  Switch,
  Table,
  TableColumn,
  TabPane,
  Tabs,
  Tag,
  Tooltip,
  Tree,
  Upload,
  MessageBox,
  Message,
  Menu,
  Submenu,
  MenuItem,
  Collapse,
  CollapseItem,
  Slider,
} from 'element-ui';
import '@/assets/style/ele-theme/index.css';
import installDirectives from '@/directives/index';
import domain from './scripts/domain';

import App from './App.vue';
import router from './router';
import message from './scripts/ele-message';
import serve from './scripts/serve';
import loading from './components/utils/loading.vue';
import store from './store';
import Msg from './components/msg/msg';
import RbDialog from './components/base/RbDialog.vue';
import RbButton from './modules/exam-report/children/RbButton.vue';
import {
  analyzeSubjectMap,
  analyzeTypeMap,
  questionTypeMap,
  analyzeGradeMap,
} from './scripts/analyzeMap';

Vue.component('rb-dialog', RbDialog);
Vue.component('rb-button', RbButton);
Vue.component(Autocomplete.name, Autocomplete);
Vue.component(Button.name, Button);
Vue.component(Cascader.name, Cascader);
Vue.component(CheckboxButton.name, CheckboxButton);
Vue.component(CheckboxGroup.name, CheckboxGroup);
Vue.component(Checkbox.name, Checkbox);
Vue.component(DatePicker.name, DatePicker);
Vue.component(Dialog.name, Dialog);
Vue.component(DropdownItem.name, DropdownItem);
Vue.component(DropdownMenu.name, DropdownMenu);
Vue.component(Dropdown.name, Dropdown);
Vue.component(FormItem.name, FormItem);
Vue.component(Form.name, Form);
Vue.component(Icon.name, Icon);
Vue.component(Input.name, Input);
Vue.component(InputNumber.name, InputNumber);
Vue.component('Loading', loading);
Vue.component(Option.name, Option);
Vue.component(OptionGroup.name, OptionGroup);
Vue.component(Pagination.name, Pagination);
Vue.component(Popover.name, Popover);
Vue.component(Popconfirm.name, Popconfirm)
Vue.component(Progress.name, Progress);
Vue.component(RadioGroup.name, RadioGroup);
Vue.component(Radio.name, Radio);
Vue.component(RadioButton.name, RadioButton);
Vue.component(Select.name, Select);
Vue.component(Steps.name, Steps);
Vue.component(Switch.name, Switch);
Vue.component(TableColumn.name, TableColumn);
Vue.component(Table.name, Table);
Vue.component(TabPane.name, TabPane);
Vue.component(Tabs.name, Tabs);
Vue.component(Tag.name, Tag);
Vue.component(Tooltip.name, Tooltip);
Vue.component(Tree.name, Tree);
Vue.component(Upload.name, Upload);
Vue.component(Menu.name, Menu);
Vue.component(Submenu.name, Submenu);
Vue.component(MenuItem.name, MenuItem);
Vue.component(Collapse.name, Collapse);
Vue.component(CollapseItem.name, CollapseItem);
Vue.component(Slider.name, Slider)

Vue.use(Loading);
Vue.use(vueResource);
Vue.use(Vuex);
Vue.use(Msg);
Vue.use(Loading);
Vue.use(installDirectives);

Vue.prototype.$EleMessageBox = MessageBox;
Vue.prototype.$EleMessage = Message;
Vue.prototype.$serve = serve;
Vue.prototype.$message = message;
Vue.prototype.$bus = new Vue();

const unlessUrls = [
  domain['examination-single']['exam-api-domain'] + '/v1/help_center/video/vote/get'
]

// 统一处理 401 状态码
Vue.http.interceptors.push((request, next) => {
  next(response => {
    if (response.status === 401 && !unlessUrls.includes(request.url)) {
      Vue.prototype.$message.error('登录已过期，请重新登录')
    }
  });
});

Vue.filter('subjectIdToText', (v, g) => {
  if (v === 7 && typeof g === 'number' && g >= 10) {
    return "思想政治"
  } else if (v === 7 && typeof g === 'number' && g <= 9) {
    return "道德与法治"
  } else {
    return analyzeSubjectMap[v] || '未知科目'
  }
});
Vue.filter('examTypeIdToText', (v) => analyzeTypeMap[v] || '未知类型');
Vue.filter('questionTypeIdToText', (v) => questionTypeMap[v] || '未知题型');
Vue.filter('gradeIdToText', (v) => analyzeGradeMap[v] ? analyzeGradeMap[v].name : '未知年级');

Vue.directive('focus', {
  inserted(el) {
    el.focus();
  },
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
