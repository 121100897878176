<template>
  <div id="app">
    <!-- <keep-alive include="main-page-wrapper" v-if="isLoginIn"> -->
    <!--   <router-view></router-view>                             -->
    <!-- </keep-alive>                                             -->
    <!-- <router-view v-if="!isLoginIn"></router-view>             -->
    <!--
      请注意这里对 MainPage 组件的缓存作用
    -->
    <router-view></router-view>
  </div>
</template>

<script>
import {
  getStsToken,
  localStoragePrefix,
} from "@/scripts/utils";
import { analyzeSubjectMap } from "./scripts/analyzeMap";
import { mapGetters } from "vuex";
export default {
  name: "App",
  data() {
    return {
      isLoginIn: true,
    }
  },
  computed: {
    ...mapGetters('user', ['userSchoolId']),
  },
  watch: {
    // 进入首页时, 用户权限数据可能更新，手动销毁组件
    $route(to, from) {
      // 退出登录的时候 移除keep-alive
      if (to.name === 'NewHomePage') {
        this.isLoginIn = false;
        this.$nextTick(() => {
          this.isLoginIn = true;
        })
      }
    },
    userSchoolId: {
      async handler(v) {
        const params = {};
        if(v) {
          params.school_id = v;
        }
        await this.$serve.getSubjectList(params);
      },
      immediate: true,
    }
  },
  mounted() {
    getStsToken();
  }
};
</script>

<style lang='less'>
@import "@/assets/style/index.less";

#app {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
</style>
