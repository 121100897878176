/* eslint-disable no-param-reassign */
import { Base64 } from "js-base64";
import { MD5 } from "crypto-js";
import Vue from "vue";
import { mainPageList } from "@/scripts/previlege";
import domain from "./domain";

const OSS = require("ali-oss");

const SALT = "c7bbd6f8356b46ad0e3b83b75c74eec7";
const HOST = domain.JLatexServer.domain;
const localStoragePrefix = "examination_";
// 用户登录或者退出清空存储信息时需要保留的字段
const saveKey = [
  `${localStoragePrefix}mode_type`,
  `${localStoragePrefix}self_list`,
  `${localStoragePrefix}version_name`,
  `${localStoragePrefix}user_account`,
  `${localStoragePrefix}selected_list`,
  `${localStoragePrefix}last_info`,
  `${localStoragePrefix}roll_back_record`,
  `${localStoragePrefix}tour`,
  `${localStoragePrefix}welcome`,
];

const IMG_PRE_FIX = 'https://contres.readboy.com';

// 处理公式标签
function formatTex(string) {
  const texRegular = /<tex[\S\s]*?data-latex="([\S\s]*?)">[\S\s]*?<\/tex>/g;
  return string.replace(texRegular, (match, $1) => {
    const head = `<img src="${HOST}tex?`;
    const tex = Base64.encodeURI(
      $1.replace(/&gt;/g, ">").replace(/&lt;/g, "<")
    );
    const token = MD5(SALT + tex);
    const body = `tex=${tex}&token=${token}`;
    const tail = '" style="vertical-align: middle;"">';
    return head + body + tail;
  });
}

// 接受地址栏中的参数
function getRequest() {
  const {
    location: { href }
  } = window;
  if (href.indexOf("?") === -1) {
    return {};
  }
  const index = href.indexOf("?");
  const querys = href.slice(index + 1).split("&");
  return Object.fromEntries(querys.map(s => s.split("=")));
}

function clearIntervalId() {
  clearInterval(localStorage.getItem(`${localStoragePrefix}timeout_id`));
}

async function getStsToken() {
  const res = await Vue.prototype.$serve.getStsToken();
  localStorage.setItem(
    `${localStoragePrefix}OSSKEY`,
    JSON.stringify(res.data.F_data)
  );
  if (localStorage.getItem(`${localStoragePrefix}timeout_id`)) {
    clearIntervalId(localStorage.getItem(`${localStoragePrefix}timeout_id`));
  }
  const timeoutId = setTimeout(() => {
    getStsToken();
  }, res.data.F_data.Expiration * 1000 - new Date().getTime());
  localStorage.setItem(`${localStoragePrefix}timeout_id`, timeoutId);
}

// 阿里图片资源域名预处理函数
/**
 * @param {String} url 传入的图片资源url
 * @param boolean [enableCompress] 是否启用压缩
 */

// 不采用a标签传递url的原因：
// decode解密后a.href会再次进行加密
function ossFn(url, enableCompress) {
  if (url.indexOf("ebag-exam.oss-cn-shenzhen.aliyuncs.com") !== -1) {
    const { AccessKeyId, AccessKeySecret, SecurityToken } = JSON.parse(
      localStorage.getItem(`${localStoragePrefix}OSSKEY`)
    );
    const client = new OSS({
      region: "oss-cn-shenzhen",
      accessKeyId: AccessKeyId,
      accessKeySecret: AccessKeySecret,
      stsToken: SecurityToken,
      bucket: "ebag-exam",
      endpoint: "oss-cn-shenzhen.aliyuncs.com"
    });
    const result = enableCompress
      ? client.signatureUrl(decodeURI(url.split(".com")[1]), {
        process: "image/quality,q_50"
      })
      : client.signatureUrl(decodeURI(url.split(".com")[1]));
    return result.replace(/^http+s?/g, "https");
  }
  return url;
}

// 存储会话级的相关数据
function saveSession(info) {
  localStorage.setItem(`${localStoragePrefix}session_id`, info.sessionId);
  localStorage.setItem(`${localStoragePrefix}user_name`, info.userName);
  localStorage.setItem(`${localStoragePrefix}user_id`, info.userId);
}

// 设置页面权限
function setAuthority(dataList, defaultList) {
  defaultList.forEach(defaultItem => {
    dataList.forEach(dataItem => {
      if (defaultItem.name === dataItem.name) {
        defaultItem.status = 1;
        if (dataItem.childs != null) {
          setAuthority(dataItem.childs, defaultItem.children);
        }
      }
    });
  });
}

async function dealRole() {
  const userType = localStorage[`${localStoragePrefix}type`];
  // const {
  //   data: { data }
  // } = await Vue.prototype.$serve.getRoleAuthority();
  let pageList = JSON.parse(JSON.stringify(mainPageList));
  if (userType !== 999) {
    pageList[0].children = pageList[0].children.filter(
      e => e.name === "阅卷任务" || e.name === "评卷监管"
    );
  }
  // setAuthority(data, pageList);

  localStorage.setItem(
    `${localStoragePrefix}main_page_list`,
    JSON.stringify(pageList)
  );
  return Promise.resolve();
}

// 登录初始化流程
async function initLoginInfo() {
  let modeType;
  let selfList;
  if (localStorage[`${localStoragePrefix}self_list`]) {
    selfList = localStorage[`${localStoragePrefix}self_list`];
  }
  if (localStorage[`${localStoragePrefix}mode_type`]) {
    modeType = JSON.parse(localStorage[`${localStoragePrefix}mode_type`]);
  } else {
    modeType = {
      scoreType: 3, // 普通打分：1，点击打分：2,快捷打分：3
      add_type: 0, // 加分方式,普通打分：0，加分打分：1，减分打分：2
      submitType: 2, // 不自动提交：1 ，自动提交：2
      traces: 0 // 点击打分模式的加分模式是否留痕，有痕1，无痕2
    };
  }

  localStorage.setItem(
    `${localStoragePrefix}mode_type`,
    JSON.stringify(modeType)
  );
  if (selfList) {
    localStorage.setItem(`${localStoragePrefix}self_list`, selfList);
  }
  getStsToken();
  return dealRole();
}

function thirdLogin(_this, from, code) {
  const formData = new FormData();
  formData.append("from", from);
  formData.append("code", code);
  return _this.$serve
    .thirdLogin(formData)
    .then(res => {
      _this.$message.success("转跳成功");
      return initLoginInfo(_this, res.body, res.body.F_data.userAccount);
    })
    .catch(err => {
      console.log(err);
      _this.$message.success("第三方登录失败，转至阅卷系统登录页");
      _this.$router.push("/Login");
    });
}

// 下载一个线上文件且格式为 blob 类型
async function downloadFileAsBlob(path) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("get", path);
    xhr.responseType = "blob";
    xhr.send();
    xhr.onload = function () {
      if (this.status === 200 || this.status === 304) {
        return resolve(this.response);
      }
    };
    xhr.onerror = function () {
      return reject(new Error("donwload file error"));
    };
  });
}

function downloadFile(fileUrl, saveName) {
  const xhr = new XMLHttpRequest();
  xhr.open("get", fileUrl);
  xhr.responseType = "blob";
  xhr.send();
  xhr.onload = function () {
    if (this.status === 200 || this.status === 304) {
      const url = URL.createObjectURL(this.response);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = saveName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }
  };
}

function treeFind(tree, id) {
  for (const data of tree) {
    if (data.question_id == id) return data;
    if (data.points && data.points.length) {
      const res = treeFind(data.points, id);
      if (res) return res;
    }
  }
  return null;
}

function readTreeNode(tree, propKey, arr) {
  for (let item of tree) {
    arr.push(item);
    if (item[propKey] && item[propKey].length) {
      readTreeNode(item[propKey], propKey, arr);
    }
  }
  return arr;
}

const debounce = (() => {
  let timer = null;
  return (callback, wait) => {
    clearTimeout(timer);
    timer = setTimeout(callback, wait);
  };
})();

function isMobile() {
  const regExp = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/;
  return regExp.test(navigator.userAgent.toLowerCase());
}

// 两个数组取交集, prop1 和 prop2 分别是 arr1 和 arr2 需要对比的属性
function getIntersection(arr1, prop1, arr2, prop2) {
  return arr1.filter(e => arr2.find(u => u[prop2] === e[prop1]));
}

// 不打开 PDF 直接进行下载
function downloadExcel(path, name) {
  const xhr = new XMLHttpRequest();
  xhr.open("get", path);
  xhr.responseType = "blob";
  xhr.send();
  xhr.onload = function () {
    if (this.status === 200 || this.status === 304) {
      // const blob = new Blob([this.response], { type: xhr.getResponseHeader('Content-Type') });
      // const url = URL.createObjectURL(blob);
      const url = URL.createObjectURL(this.response);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }
  };
}

function getRandomCode() {
  let code = '';
  for (let i = 0; i < 6; i++) {
    code += parseInt(Math.random() * 10);
  }
  return code;
}

/**
 * 维护者寄语：
 * Wed Jun 26 2024 14:36:09 GMT+0800 (中国标准时间)
 * 该方法已废弃！
 * 因为精准后台增加了'自定义班级名称' 的功能，所以不再根据班级名称中的数字进行排序了，
 * 理论上所有班级列表的接口都由后端排序后再提供
 */
function customSort(list, key) {
  list.sort((a, b) =>
    +a[key].replace(/[^0-9]/gi, "") > +b[key].replace(/[^0-9]/gi, "")
      ? 1
      : -1
  );
}

// 获取一个数组，数组长度为 count, 每个元素是取值范围为 [min, max] 的随机数
function getRandomNumArr(min, max, count) {
  const retArr = [];
  while (count) {
    const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
    if (!retArr.includes(randomNum)) {
      retArr.push(randomNum)
      count--;
    }
  }
  return retArr;
}

async function customTimeout(delay = 1000) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve()
    }, delay)
  })
}

function getBaseURL() {
  let base = ''
  const deployEnv = process.env.DREAMENV
  const date = new Date()
  const prefix = `${process.env.CI_PROJECT_NAME}/${date.getFullYear()}${date.getMonth() + 1
    }${date.getDate()}`
  switch (deployEnv) {
    case 'TEST':
      base = process.env.TEST_RESOURCE_DOMAIN + prefix
      break
    case 'PROD':
      base = process.env.PROD_RESOURCE_DOMAIN + prefix
      break
    default:
      base = './'
      break
  }
  return base
}

function num2Chinese(number) {
  const map = {
    1: '一',
    2: '二',
    3: '三',
    4: '四',
    5: '五',
    6: '六',
    7: '七',
    8: '八',
    9: '九',
    0: ''
  }
  if (number < 10) {
    return map[number]
  } else if (number < 20) {
    return '十' + map[number % 10]
  } else if (number < 100) {
    return map[Math.floor(number / 10)] + '十' + map[number % 10]
  } else {
    return ''
  }
}

function numberToUpperCaseLetter(number) {
  // 阿拉伯数字到英文字母的偏移量为65，因为'A'在ASCII码表中的值是65
  const asciiCodeOffset = 65;
  // 将输入的数字加上偏移量得到对应的ASCII码，并通过String.fromCharCode转换为字母
  return String.fromCharCode(number + asciiCodeOffset);
}

// 26个英文字母映射到数字0-25
function letterToNumber(letter) {
  const upperCaseLetter = letter.toUpperCase();
  const baseCharCode = 'A'.charCodeAt(0);
  return upperCaseLetter.charCodeAt(0) - baseCharCode;
}



const groupBy = (array, f) => {
  let groups = {};
  array.forEach(function (o) {
    var group = JSON.stringify(f(o));
    groups[group] = groups[group] || [];
    groups[group].push(o);
  });
  return Object.keys(groups).map(function (group) {
    return groups[group];
  });
};

// 数组对象按照某个属性进行分组
function arrayGroupBy(list, groupId) {
  let sorted = groupBy(list, function (item) {
    return [item[groupId]];
  });
  return sorted;
};

// 根据对象数据某个属性来去重
function deduplicateArrayByProperty(array, property) {
  const uniqueValues = new Set();

  return array.reduce((result, obj) => {
    const value = obj[property];

    if (!uniqueValues.has(value)) {
      uniqueValues.add(value);
      result.push(obj);
    }

    return result;
  }, []);
}

function chineseToArabic(chineseNum) {
  const numMap = {
    '一': 1,
    '二': 2,
    '三': 3,
    '四': 4,
    '五': 5,
    '六': 6,
    '七': 7,
    '八': 8,
    '九': 9,
    '十': 10
  };

  if (numMap[chineseNum]) return numMap[chineseNum];

  // Special handling for numbers 11-19
  if (chineseNum.startsWith('十')) {
    return 10 + numMap[chineseNum[1]];
  }

  // Special handling for number 20
  if (chineseNum === '二十') return 20;
}


function hasProperty(obj, key) {
  return Object.hasOwnProperty.call(obj, key)
}

// 将一个一维数组转换为固定长度的二维数组
function chunkArray(arr, chunkSize) {
  if (!arr?.length) {
    return []
  }
  let result = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    let chunk = arr.slice(i, i + chunkSize);
    result.push(chunk);
  }
  return result;
}

function getAliyunImageAsFile(url) {
  return new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.onload = function () {
      if (this.status === 200) {
        let blob = this.response;
        let filename = url.substring(url.lastIndexOf('/') + 1); // 从URL中提取文件名
        let file = new File([blob], filename, { type: blob.type });
        resolve(file);
      } else {
        reject(new Error('Failed to fetch the image'));
      }
    };

    xhr.onerror = function () {
      reject(new Error('Network error'));
    };

    xhr.send();
  });
}

function getChoiceScore(question) {
  const {
    object_struct: { question_type },
  } = question;
  switch (question_type) {
    case 2: // 多选题
      return getMultipleChoiceScore(question);
      break;

    case 99:  // 自定义选择题
      return getCustomChooseScore(question);
      break;

    default:
      return getSingleChoiceScore(question);
      break;
  }
}

function getCustomChooseScore(question) {
  const {
    object_data: {
      answer,
      custom_option: {
        custom_option_scores,   // 各选项得分
        error_option_score,     // 错选是否给分
        exceed_option_score,    // 超出选项个数是否给分
        missing_option_score,   // 漏选是否给分
      },
    },
    object_struct: { correct_answer },
  } = question;
  if (
    (answer.length > correct_answer.length && exceed_option_score === 0) ||
    (answer.length < correct_answer.length && missing_option_score === 0) ||
    (!answer.every((e) => correct_answer.includes(e)) && error_option_score === 0)
  ) {
    return 0;
  }
  return custom_option_scores
    .filter((e) => answer.includes(e.index))
    .map((e) => e.score)
    .reduce((a, b) => a + b, 0);
}

function getMultipleChoiceScore(question) {
  const {
    object_data: { answer, full_score },
    object_struct: { correct_answer, missing_score },
  } = question;
  if (answer.every((e) => correct_answer.includes(e))) {
    return answer.length === correct_answer.length ? full_score : missing_score;
  } else {
    return 0;
  }
}

function getSingleChoiceScore(question) {
  const {
    object_data: { answer, full_score },
    object_struct: { correct_answer },
  } = question;
  return answer[0] === correct_answer ? full_score : 0;
}

function copyTextToClipboard(text) {
  return new Promise((resolve, reject) => {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    try {
      document.execCommand('copy');
      document.body.removeChild(textarea);
      resolve('ok')
    } catch (error) {
      document.body.removeChild(textarea);
      reject(error)
    }
  })
}

/**
 * 批改试卷模块中，获取题目的得分点列表
 * 并将新题目的得分设置为 null
 */
function getScorePointList(questionBlock) {
  if (!questionBlock) {
    return [];
  }
  const {
    block_data: { points },
  } = questionBlock;
  const result = points
    .map((point) => (point.point_type === 1 ? point : point.points))
    .flat(1)
    .map((v) => ({
      data: v,
      hasError: false,
      deltaScores: [],
    }));
  result.forEach((v) => {
    if (questionBlock.status === 0) {
      v.data.score = null;
    }
  });
  return result;
}

// 两个数相除 不四舍五入，同时保留两位小数，返回百分数形式
function divideAndTruncate(a, b, decimals) {
  // 确保输入的是数字并且小数位数是正整数
  if (isNaN(a) || isNaN(b) || !Number.isInteger(decimals) || decimals < 0) return "0%";

  // 进行除法运算并乘以100得到百分数
  const percentage = (a / b) * 100;

  // 截取小数部分（不四舍五入）
  const factor = Math.pow(10, decimals);
  const truncatedPercentage = Math.floor(percentage * factor) / factor;

  // 转换为字符串并确保有足够的小数位
  let result = truncatedPercentage.toString();
  if (result.indexOf('.') === -1) {
    // 如果结果中没有小数点，添加小数点和适当数量的零
    result += '.' + '0'.repeat(decimals);
  } else {
    // 否则，确保有足够的小数位
    const decimalPart = result.split('.')[1];
    const missingZeros = decimals - decimalPart.length;
    result += '0'.repeat(missingZeros);
  }

  // 添加百分号符号
  return result + '%';
}

// 根据相对路径的地址来添加前缀
function addPrefixToImgSrc(str) {
  if (!str) return str;
  const regExp = /<img\s+[^>]*src="([^"]*)"/g;
  const result = str.replace(regExp, function (match, p1) {
    if (!p1.startsWith('https://')) {
      return match.replace(p1, IMG_PRE_FIX + p1);
    }
    return match;
  });

  return result;
}

export {
  getRequest,
  formatTex,
  ossFn,
  getStsToken,
  clearIntervalId,
  localStoragePrefix,
  thirdLogin,
  initLoginInfo,
  saveKey,
  downloadFileAsBlob,
  downloadFile,
  treeFind,
  readTreeNode,
  debounce,
  isMobile,
  getIntersection,
  downloadExcel,
  getRandomCode,
  customSort,
  getRandomNumArr,
  customTimeout,
  getBaseURL,
  num2Chinese,
  arrayGroupBy,
  deduplicateArrayByProperty,
  chineseToArabic,
  hasProperty,
  chunkArray,
  getAliyunImageAsFile,
  getChoiceScore,
  copyTextToClipboard,
  numberToUpperCaseLetter,
  letterToNumber,
  getScorePointList,
  divideAndTruncate,
  addPrefixToImgSrc
};
