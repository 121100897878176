import serve from '@/scripts/serve';
import { getIntersection } from '@/scripts/utils';
import store from '../../store';

let examId = -1;
let markerId = '';
let userPermission = {};
let examDistricts = [];
let schoolList = [];
let filterSchoolList = [];

async function getExamDistricts() {
  const { body: { data } } = await serve.getExamDistricts(examId);
  examDistricts = data || [];
}

async function getSchoolAndClassList() {
  const { body: { data } } = await serve.getSchoolAndClass({
    exam_id: examId,
    city_id: examDistricts.map((e) => e.F_id).join(),
    district_id: examDistricts
      .map((e) => e.children)
      .flat(2)
      .map((e) => e.F_id)
      .join(","),
  })
  schoolList = data || [];
}

async function getTreeStructure() {

  // 把学校列表加到对应的各地区下面
  examDistricts.forEach(city => {
    const countyList = city.children;
    countyList.forEach((county) => {
      county.children = schoolList.filter((e) =>
        county.F_id === e.district_id
      ).map(e => {
        let { classes, school_id, school_name } = e;
        // classes.sort((a, b) => {
        //   return (
        //     a.class_name.replace(/[^0-9]/gi, "") -
        //     b.class_name.replace(/[^0-9]/gi, "")
        //   );
        // });
        classes = classes.map(({ class_id: F_id, class_name: F_name }) => ({ F_id, F_name }))
        return { F_id: school_id, F_name: school_name, classes }
      })
    })
  })



  if (!userPermission.permission) {
    const { body: { city_id } } = await serve.getMarkingTeacherCity({
      exam_id: examId,
      marker_id: markerId,
    })
    examDistricts = examDistricts.filter(e => e.F_id === city_id);
    return;
  }
  const { permission: { city_list, county_list, school_list } } = userPermission;

  // 市、区、学校 这3个等级跟 userPermission 取交集
  if (city_list.length) {
    examDistricts = getIntersection(examDistricts, 'F_id', city_list, 'F_id');
  }
  examDistricts.forEach(city => {
    if (county_list.length) {
      city.children = getIntersection(city.children, 'F_id', county_list, 'F_id');
    }
    city.children.forEach(county => {
      if (school_list.length) {
        county.children = getIntersection(county.children, 'F_id', school_list, 'F_id');
      }
    })
  })
}

function setScoolList(data) {
  data.forEach((item) => {
    if (item.F_level) {
      if(item.children && item.children.length > 0) {
        setScoolList(item.children);
      }
    } else {
      filterSchoolList.push(item);
    }
  });
}

export async function getFilterData(exam_id, user_permission, marker_id) {
  examId = exam_id;
  markerId = marker_id;
  userPermission = user_permission;
  await getExamDistricts();
  await getSchoolAndClassList();
  await getTreeStructure();
  filterSchoolList = [];
  setScoolList(examDistricts)
  store.commit('affairManage/SET_SCHOOL_LIST', {
    filterSchoolList
  })
  return examDistricts;
}
