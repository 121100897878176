/**
 * <考试报表> 模块相关的 api
 * 从考试综合服务平台项目搬过来的, 有待补充改善
 */
import exam from './modules/exam';
import searchScore from './modules/searchScore';
import examReport from './modules/examReport';
import searchInput from './modules/searchInput';

const reportAPI = {
  exam,
  examReport,
  searchInput,
  searchScore,
};

export default reportAPI;
